import {useState, useEffect} from 'react';
import {useQuery} from 'react-query';
import PropTypes from 'prop-types';
import {Dialog} from 'primereact/dialog';
import {fetchMementos} from 'Components/Common/Functions';
import Spinner from 'Components/Common/Spinner';
import Pagination from 'Components/Common/Pagination';
import useSessionStorage from 'Hooks/useSessionStorage';
import {queryClient} from 'App';

LinkMementoList.propTypes = {
  file: PropTypes.object.isRequired,
  displayBasic: PropTypes.bool.isRequired,
  setDisplayBasic: PropTypes.func.isRequired
};

function saveChanges(file, getItems) {
  let storedAuthToken = localStorage.getItem('token');
  let prevMementos = file?._listMementos?.Memento?.map((item) => item._idMemento) ?? [];
  let mementos = Object.keys(getItems());
  let params = {
    idFile: file._idFichero,
    idMementos: mementos,
    idMementosPrev: prevMementos
  };
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${storedAuthToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  };

  fetch('/api/adm/File/LinkMementos', requestOptions)
    .then((res) => res.json())
    .then(() => queryClient.refetchQueries());
}

function LinkMementoList({file, displayBasic, setDisplayBasic}) {
  const {getItem, toggleItem, getItems} = useSessionStorage(`fileLinkedMemento${file._idFichero}`);
  const [slug, setSlug] = useState('');
  const [input, setInput] = useState('');
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const {data, isPreviousData, isLoading} = useQuery(
    [`mementos-adm-0-2-${slug}`, currentPage],
    fetchMementos({
      currentPage: currentPage,
      indPublish: 0,
      url: '/api/adm/Memento/List',
      indRemoved: 2,
      search: slug
    })
  );

  useEffect(() => {
    if (!isLoading) {
      let total = data.result?._IntTotalRecs;
      let totalPages = Math.ceil(total / 10);
      setTotalPages(total && totalPages);
    }
    // eslint-disable-next-line
  }, [data, isLoading]);

  const handleInputClick = (e) => {
    if (!e.target.value) setSlug('');
  };

  const handleClick = () => {
    saveChanges(file, getItems);
    setDisplayBasic(false);
  };

  const handleSubmit = (e) => {
    setCurrentPage(1);
    e.preventDefault();
    setSlug(input);
  };

  const renderFooter = () => {
    return (
      <div className="modal-footer">
        <div className="d-grid mx-auto">
          <Pagination
            totalPages={totalPages ?? 1}
            currentPage={currentPage}
            generate={false}
            setPage={setCurrentPage}
          />
        </div>
        <button className="btn btn-secondary" onClick={() => setDisplayBasic(false)}>
          Cerrar
        </button>
        <button className="btn btn-primary" onClick={() => handleClick()}>
          Guardar
        </button>
      </div>
    );
  };

  return (
    <Dialog
      header="Asociar mementos"
      visible={displayBasic}
      style={{width: '50vw'}}
      modal
      dismissableMask
      baseZIndex={999999999}
      onHide={() => setDisplayBasic(false)}
      footer={renderFooter()}
    >
      <div className="content">
        <div className="modal-body">
          {(isLoading || isPreviousData) && <Spinner type={'-relative'} margin={'mt-5'} />}
          {!isLoading && !isPreviousData && (
            <>
              <form className="input-group input-group-sm mb-3" onSubmit={(e) => handleSubmit(e)}>
                <span className="input-group-text" id="inputGroup-sizing-sm">
                  Buscar Mementos
                </span>
                <input
                  type="search"
                  className="form-control"
                  required
                  minLength="2"
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  onInput={(e) => handleInputClick(e)}
                />
                <button className="btn btn-primary" id="button-addon2">
                  <span className="lf-icon-search"></span>
                </button>
              </form>
              {data.data.Memento.length == 0 && (
                <div className="d-flex">
                  <p className="m-auto">No hay resultados</p>
                </div>
              )}
              {data.data.Memento.map((item) => (
                <div className="card mb-3" key={item._idMemento + Math.random()}>
                  <div className="media">
                    <img
                      className="align-self-start"
                      width="100px"
                      src={item.image}
                      alt={item._descripcion}
                    />
                    <label className="form-check-label m-auto" htmlFor="flexSwitchCheckDefault">
                      {item._descripcion}
                    </label>
                    <div className="form-check form-switch ml-auto my-auto">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        defaultChecked={getItem(item._idMemento)}
                        onChange={() => {
                          toggleItem(item._idMemento);
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </Dialog>
  );
}

export default LinkMementoList;
