const FooterSocial = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 footer-logo">
            <a href="https://lefebvre.es/" target="_blank" title="Enlace externo. Abre en ventana nueva">
              <img
                alt="LEFEBVRE"
                src="https://assets.lefebvre.es/media/logos/web/comunes/lefebvre-bl-160x32.png"
              />
            </a>
          </div>
          <ul className="col-12 col-md-6 social-squares">
            <li>
              <a
                href="https://www.facebook.com/Lefebvre.ES/"
                target="_blank"
                title="Enlace externo. Abre en ventana nueva"
              >
                <span className="lf-icon-facebook-round" title="Facebook"></span>
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/lefebvre_es"
                target="_blank"
                title="Enlace externo. Abre en ventana nueva"
              >
                <span className="lf-icon-linkedin-round" title="LinkedIn"></span>
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/c/lefebvre_es"
                target="_blank"
                title="Enlace externo. Abre en ventana nueva"
              >
                <span className="lf-icon-youtube-round" title="YouTube"></span>
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/Lefebvre_ES"
                target="_blank"
                title="Enlace externo. Abre en ventana nueva"
              >
                <span className="lf-icon-twitter-round" title="Twitter"></span>
              </a>
            </li>
          </ul>
        </div>
        <div className="row">
          <p className="col-12">
            ©Lefebvre {new Date().getFullYear()}. Todos los derechos reservados.{' '}
            <span>
              <a
                href="https://lefebvre.es/aviso-legal/"
                target="_blank"
                title="Enlace externo. Abre en ventana nueva"
              >
                Aviso legal
              </a>{' '}
              &nbsp;|&nbsp;
              <a
                href="https://lefebvre.es/politica-privacidad/"
                target="_blank"
                title="Enlace externo. Abre en ventana nueva"
              >
                Política de privacidad
              </a>{' '}
              &nbsp;|&nbsp;
              <a
                href="https://lefebvre.es/politica-cookies/"
                target="_blank"
                title="Enlace externo. Abre en ventana nueva"
              >
                Política de cookies
              </a>
            </span>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default FooterSocial;
