import {useState} from 'react';
import {useIsFetching} from 'react-query';
import PropTypes from 'prop-types';
import MementoElement from './MementoElement';
import Spinner from 'Components/Common/Spinner';
import useSessionStorage from 'Hooks/useSessionStorage';
import LinkMementoList from './LinkMementoList';
import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

MementoList.propTypes = {
  setList: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired
};

function MementoList({setList, item, refetch}) {
  const {removeItem} = useSessionStorage('fileOpened');
  const isFetching = useIsFetching();
  const [response] = useState({code: 0, message: ''});
  const [displayBasic, setDisplayBasic] = useState(false);
  let element = item?._listMementos?.Memento;
  if (element) element = Array.isArray(element) ? element : [element];
  else element = [];

  return (
    <>
      {isFetching != 0 && (
        <div className="card-body p-2" style={{backgroundColor: '#e6eae6'}}>
          <div style={{height: '25vh'}}>
            <Spinner type={'-relative'} />
          </div>
        </div>
      )}
      {!isFetching && (
        <div className="card-body p-2" style={{backgroundColor: '#e6eae6'}}>
          <button
            className="btn-close float-right"
            aria-label="Cerrar"
            onClick={() => {
              setList(false);
              removeItem(item._idFichero);
            }}
          ></button>
          <div className="container m-3">
            <h5 className="p-2">Mementos vinculados</h5>

            {response.code > 0 && response.code && (
              <div
                className={`alert alert-dismissible fade show mr-3 ${
                  response.code == 200 ? 'alert-success' : 'alert-danger'
                }`}
                role="alert"
              >
                {response.message}
              </div>
            )}

            <div className="row row-cols-1 row-cols-md-5 mr-3 g-4">
              {element.map((elem, key) => {
                return <MementoElement key={key} item={elem} element={item} refetch={refetch} />;
              })}
              <div className="col" style={{minHeight: '303px'}}>
                <div className="card h-100 bg-transparent">
                  <button
                    className="h-100 btn btn-outline-secondary border-0"
                    onClick={() => setDisplayBasic(true)}
                  >
                    <i className="bi bi-plus-circle-fill"></i>
                    <br /> Asociar mementos
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <LinkMementoList file={item} displayBasic={displayBasic} setDisplayBasic={setDisplayBasic} />
    </>
  );
}

export default MementoList;
