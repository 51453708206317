import propTypes from 'prop-types';
import {useState, useEffect} from 'react';
import Spinner from 'Components/Common/Spinner';

Form.propTypes = {
  element: propTypes.object,
  handleClose: propTypes.func.isRequired,
  refetch: propTypes.func.isRequired
};

async function saveChanges(id, title, desc, file, setIsFetching, setData, refetch) {
  let storedAuthToken = localStorage.getItem('token');
  let params = {
    title: title,
    description: desc,
    fileName: file?.name ?? file
  };
  if (id) {
    params.idFile = id;
  }

  if (file?.size > 16000000) {
    setIsFetching(false);
    return setData({code: 413, message: 'El archivo es demasiado grande'});
  }

  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${storedAuthToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  };

  fetch('/api/adm/File/Change', requestOptions)
    .then((res) => res.json())
    // eslint-disable-next-line space-before-function-paren
    .then(async (res) => {
      setData(res);
      if (file?.name && res?.code == 200) {
        let data = new FormData();
        data.append('file', file);
        requestOptions.body = data;
        delete requestOptions.headers['Content-Type'];
        fetch('/api/adm/File/UploadFile', requestOptions)
          .then((res) => res.json())
          .then((res) => {
            if (res?.code == 400) setData(res);
          });
      }
      setIsFetching(false);
      refetch();
    });
}

export default function Form({element, handleClose, refetch}) {
  const hasElement = element ? true : false;
  const [isFetching, setIsFetching] = useState(false);
  const [response, setResponse] = useState({code: 0, message: ''});
  const [titleValue, setTitleValue] = useState(element?._titulo ?? '');
  const [descValue, setDescValue] = useState(element?._descripcion ?? '');
  const [fileValue, setFileValue] = useState(element?._nombre ?? '');
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!isFetching) setResponse({code: data?.code, message: data?.message});
    // eslint-disable-next-line
  }, [isFetching, data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsFetching(true);
    saveChanges(
      element?._idFichero,
      titleValue,
      descValue,
      fileValue,
      setIsFetching,
      setData,
      refetch
    );
  };

  const handleFileChange = (e) => {
    setFileValue(e.target.files[0]);
  };

  const unsetResponse = () => {
    setResponse({code: 0, message: ''});
  };

  if (isFetching)
    return (
      <div style={{height: '25vh'}}>
        <Spinner type={'-relative'} />
      </div>
    );

  return (
    <>
      <button
        className="btn-close float-right"
        aria-label="Cerrar"
        onClick={() => handleClose(false)}
      ></button>
      <div className="container m-3">
        <h5 className="p-2">{hasElement ? 'Edición' : 'Creación'} de la Descarga</h5>

        {response.code > 0 && response.code && (
          <div
            className={`alert alert-dismissible fade show mr-3 ${
              response.code == 200 ? 'alert-success' : 'alert-danger'
            }`}
            role="alert"
          >
            {response.message}
          </div>
        )}

        {((response.code != 200 && !hasElement) || hasElement) && (
          <form onSubmit={(e) => handleSubmit(e)} onClick={() => unsetResponse()}>
            <div className="form-floating mb-3 mr-3">
              <input
                type="text"
                className="form-control"
                id="floatingInputTitle"
                value={titleValue}
                required
                onChange={(e) => setTitleValue(e.target.value)}
              />
              <label htmlFor="floatingInputTitle">Título</label>
            </div>
            <div className="form-floating mb-3 mr-3">
              <input
                type="text"
                className="form-control"
                id="floatingInputDesc"
                value={descValue}
                required
                onChange={(e) => setDescValue(e.target.value)}
              />
              <label htmlFor="floatingInputDesc">Descripción</label>
            </div>
            {!hasElement && (
              <>
                <div className="input-group mb-3 mr-3">
                  <label className="input-group-text" htmlFor="inputGroupFile">
                    Fichero
                  </label>
                  <input
                    type="file"
                    className="form-control mr-3"
                    id="inputGroupFile"
                    required={!element}
                    onChange={(e) => handleFileChange(e)}
                  />
                </div>
                <div className="alert alert-primary mr-3 py-1" role="alert">
                  Tamaño máximo de subida de ficheros: <span className="fw-bold">16 Mb</span>
                </div>
              </>
            )}
            <div className="d-grid mx-auto">
              <button className="btn btn-success mr-3">Enviar</button>
            </div>
          </form>
        )}
      </div>
    </>
  );
}
