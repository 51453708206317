import {useState, createContext} from 'react';
import {Route, Switch, BrowserRouter as Router} from 'react-router-dom';
import {QueryClient, QueryClientProvider} from 'react-query';
import Header from 'Components/Common/Header/Header';
import * as Memento from 'Components/Memento/';
import Admin from 'Components/Admin/Admin';
import Footer from 'Components/Common/Footer/Footer';
import GenerateUrl from 'Components/Common/GenerateUrl';
import 'App.scss';


export const queryClient = new QueryClient();
queryClient.setDefaultOptions({
  queries: {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60,
    keepPreviousData: true,
    retry: 0,
    notifyOnChangePropsExclusions: ['isStale']
  }
});

export const AdmSlugContext = createContext();
export const AllAreasContext = createContext();
export const FilterContext = createContext();
export const AreaContext = createContext();
export const SearchContext = createContext();
export const CurrentPageContext = createContext();
export const GenerateUrlContext = createContext();
export const AuthContext = createContext();

export default function App() {
  const storedAuthToken = localStorage.getItem('token');
  const [allAreas, setAllAreas] = useState('');
  const [filter, setFilter] = useState('todos');
  const [area, setArea] = useState('');
  const [admSlug, setAdmSlug] = useState('');
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [generate, setGenerate] = useState(false);
  const [isLogged, setIsLogged] = useState(false);

  if (storedAuthToken && !isLogged) setIsLogged(true);

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AuthContext.Provider value={{isLogged, setIsLogged}}>
          <AllAreasContext.Provider value={{allAreas, setAllAreas}}>
            <FilterContext.Provider value={{filter, setFilter}}>
              <AreaContext.Provider value={{area, setArea}}>
                <SearchContext.Provider value={{search, setSearch}}>
                  <CurrentPageContext.Provider value={{currentPage, setCurrentPage}}>
                    <GenerateUrlContext.Provider value={{generate, setGenerate}}>
                      <AdmSlugContext.Provider value={{admSlug, setAdmSlug}}>
                        <Header />
                        <Switch>
                          <Route path="/adm/">
                            <Admin />
                          </Route>
                          <Route path="/memento/:slug">
                            <Memento.Details />
                          </Route>
                          <Route path={'/mementos/:filter'}>
                            <Memento.Filter />
                          </Route>
                          <Route path={'/area/:area'}>
                            <Memento.Area />
                          </Route>
                          <Route path={'/buscar/:search/:page(\\d+)?'}>
                            <Memento.Search />
                          </Route>
                          <Route path={'/:page(\\d+)?'}>
                            <Memento.Page />
                          </Route>
                        </Switch>
                        {generate && <GenerateUrl />}
                      </AdmSlugContext.Provider>
                    </GenerateUrlContext.Provider>
                  </CurrentPageContext.Provider>
                </SearchContext.Provider>
              </AreaContext.Provider>
            </FilterContext.Provider>
          </AllAreasContext.Provider>
        </AuthContext.Provider>
        <Footer />
      </Router>
    </QueryClientProvider>
  );
}
