import {useState, useEffect, useContext} from 'react';
import {useQuery} from 'react-query';
import {useHistory} from 'react-router-dom';
import {queryClient, AuthContext} from 'App';
import Spinner from 'Components/Common/Spinner';

function fetchLogin(login, pass) {
  let params = {
    username: login,
    password: pass
  };

  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(params)
  };

  return () => fetch('/api/adm/login', requestOptions).then((res) => res.json());
}

export default function Login() {
  const history = useHistory();
  const {isLogged, setIsLogged} = useContext(AuthContext);
  const [login, setLogin] = useState('');
  const [pass, setPass] = useState('');
  const [call, setCall] = useState(false);
  const {isFetching, data} = useQuery('login', fetchLogin(login, pass), {enabled: call});

  useEffect(() => {
    if (!isFetching && !isLogged) {
      setCall(false);
      if (data?.token) {
        localStorage.setItem('token', data.token);
        setIsLogged(true);
        history.push('/adm');
      }
    }
    // eslint-disable-next-line
  }, [isFetching, data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    queryClient.invalidateQueries('login');
    setCall(true);
  };

  const handleInputLogin = (e) => {
    if (data) data.message = false;
    setLogin(e.target.value);
  };

  const handleInputPass = (e) => {
    if (data) data.message = false;
    setPass(e.target.value);
  };

  if (call)
    return (
      <div style={{height: '25vh'}}>
        <Spinner type={'-relative'} />
      </div>
    );

  if (!call)
    return (
      <form className="container p-5 w-25" onSubmit={(e) => handleSubmit(e)}>
        <h2 className="text-center mb-5">Autorización</h2>
        {data?.message && (
          <div className="alert alert-danger" role="alert">
            Credenciales incorrectos
          </div>
        )}
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            placeholder="Nombre de usuario"
            value={login}
            required
            minLength={3}
            onChange={(e) => handleInputLogin(e)}
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            className="form-control"
            placeholder="Contraseña"
            required
            minLength={5}
            onChange={(e) => handleInputPass(e)}
          />
        </div>
        <div className="form-group">
          <button type="submit" className="btn btn-primary btn-block p-3">
            Entrar
          </button>
        </div>
      </form>
    );
}
