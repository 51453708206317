import {useContext, useEffect} from 'react';
import {Route, Switch, useRouteMatch, Redirect} from 'react-router-dom';
import Login from './Components/Login';
import Logout from './Components/Logout';
import CreateUser from './Components/CreateUser';
import AdminPanel from './AdminPanel/AdminPanel';
import {AuthContext, GenerateUrlContext, CurrentPageContext} from 'App';

export default function Admin() {
  let {url} = useRouteMatch();
  const {isLogged} = useContext(AuthContext);
  const {setGenerate} = useContext(GenerateUrlContext);
  const {setCurrentPage} = useContext(CurrentPageContext);

  useEffect(() => {
    setGenerate(false);
    setCurrentPage(1);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!isLogged && <Redirect to="/adm/login" />}
      <Switch>
        <Route exact path={`${url}/login`}>
          {isLogged ? <Redirect to="/adm" /> : <Login />}
        </Route>
        <Route exact path={`${url}/logout`}>
          {isLogged && <Logout />}
        </Route>
        <Route path="/adm/createUser">{isLogged && <CreateUser />}</Route>
        <Route path="/">{isLogged && <AdminPanel />}</Route>
      </Switch>
    </>
  );
}
