import {useContext} from 'react';
import {Redirect} from 'react-router-dom';
import {AuthContext} from 'App';

export default function Login() {
  const {isLogged, setIsLogged} = useContext(AuthContext);

  if (isLogged) {
    setIsLogged(false);
    localStorage.removeItem('token');
  }

  return <Redirect to={'/'} />;
}
