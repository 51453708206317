export const TITLE = 'Extras Mementos';
export const PAGE_LIMIT = 20;

export const FILTER = [
  {id: 0, slug: 'todos', value: 'Todos'},
  {id: 1, slug: 'ultima-semana', value: 'Última semana'},
  {id: 2, slug: 'ultimo-mes', value: 'Último mes'},
  {id: 3, slug: 'ultimos-12-meses', value: 'Último año'}
];
/*
export const SHOP_URL =
  'https://efl.es/catalogo/actum-actualidad-mementos';*/
