import {useContext} from 'react';
import HeaderMobile from './HeaderMobile';
import HeaderDesktop from './HeaderDesktop';
import {GenerateUrlContext} from 'App';

const Header = () => {
  const {setGenerate} = useContext(GenerateUrlContext);

  const toggleGenerate = () => {
    setGenerate(true);
  };

  return (
    <>
      <HeaderMobile toggleGenerate={toggleGenerate} />
      <HeaderDesktop toggleGenerate={toggleGenerate} />
    </>
  );
};

export default Header;
