import {useState, useEffect, useContext} from 'react';
import {useQueries} from 'react-query';
import Pagination from 'Components/Common/Pagination';
import Spinner from 'Components/Common/Spinner';
import Viewer from 'Components/Common/Viewer/Viewer';
import CreateForm from './Components/CreateForm';
import ElementCard from './Components/ElementCard';
import {AdmSlugContext} from 'App';

const orders = ['Fecha', 'Título', 'Descripción', 'Nombre de fichero'];
const orderFields = ['FECHA', 'TITULO', 'DESCRIPCION', 'NOMBRE'];
const types = [
  <span className="lf-icon-arrow-down" key="0"></span>,
  <span className="lf-icon-arrow-up" key="1"></span>
];

function listFiles(currentPage, search, orderField, orderType, eliminated) {
  let storedAuthToken = localStorage.getItem('token');
  let type = orderType == 0 ? 'DESC' : 'ASC';
  let order = orders.indexOf(orderField);
  let params = {
    numPage: currentPage,
    searchTerm: search,
    orderField: orderFields[order],
    orderType: type,
    eliminated: eliminated
  };

  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${storedAuthToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  };

  return () => fetch('/api/adm/File/List', requestOptions).then((res) => res.json());
}

export default function DownloadsPanel() {
  const [orderField, setOrderField] = useState('Fecha');
  const [orderType, setOrderType] = useState(0);
  const [create, setCreate] = useState(false);
  const [selected, setSelected] = useState({});
  const [eliminated, setEliminated] = useState(2);
  const [fileType, setFileType] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const {admSlug, setAdmSlug} = useContext(AdmSlugContext);
  const results = useQueries([
    {
      queryKey: [`file-list-${admSlug}-${orderField}-${orderType}-${eliminated}`, currentPage],
      queryFn: listFiles(currentPage, admSlug, orderField, orderType, eliminated)
    },
    {
      queryKey: [`file-list-${admSlug}-${orderField}-${orderType}-${eliminated}`, currentPage + 1],
      queryFn: listFiles(currentPage + 1, admSlug, orderField, orderType, eliminated)
    }
  ]);
  const result = results[0];

  useEffect(() => {
    if (!result.isLoading) {
      let total = result.data?.result?._IntTotalRecs;
      let totalPages = Math.ceil(total / 10);
      setTotalPages(total && totalPages);
    }
    // eslint-disable-next-line
  }, [result.data, result.isLoading]);

  const handleChange = (e, field) => {
    e.preventDefault();
    if (orderField != field) {
      setOrderField(field);
      setOrderType(0);
    } else setOrderType(1 - orderType);
    setCurrentPage(1);
  };

  return (
    <>
      <div className="card-body border-bottom p-2">
        <div className="container d-flex align-items-center">
          <h4 className="mr-auto">Gestión de Descargas</h4>

          <div className="dropdown mr-auto">
            <button
              className="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownShort"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {orderField} ({types[orderType]})
            </button>
            <div className="dropdown-menu dd-short" aria-labelledby="dropdownShort">
              {orders.map((item, key) => {
                return (
                  <a
                    className="dropdown-item"
                    href="#"
                    key={key}
                    onClick={(e) => handleChange(e, item)}
                  >
                    {item} {orderField === item && <>({types[1 - orderType]})</>}
                  </a>
                );
              })}
            </div>
          </div>

          <div className="btn-group flex-wrap">
            {eliminated == 2 && (
              <button
                type="button"
                className="btn btn-success"
                title="Añadir Descarga"
                onClick={() => setCreate(!create)}
              >
                {!create && <i className="bi bi-plus"></i>}{' '}
                {`${create ? 'Cerrar formulario' : 'Añadir Descarga'}`}
              </button>
            )}
            <button
              className={`btn btn-outline-secondary ${eliminated == 1 ? 'active' : ''}`}
              title="Papelera"
              onClick={() => setEliminated(3 - eliminated)}
            >
              {eliminated == 2 && (
                <>
                  <i className="bi bi-trash-fill"></i> Papelera
                </>
              )}
              {eliminated == 1 && <>Volver al listado anterior</>}
            </button>
          </div>
        </div>
        {admSlug != '' && (
          <div className="container d-flex">
            <h5 className="pt-2">
              <strong>Contiene:</strong> {admSlug}
            </h5>
            <button
              className="btn btn-outline-secondary border-0 ml-5"
              onClick={() => {
                setAdmSlug('');
                result.refetch();
              }}
            >
              <i className="bi bi-backspace-fill"></i>
            </button>
          </div>
        )}
        {create && <CreateForm handleClick={setCreate} refetch={result.refetch} />}
      </div>
      <div className="card-body p-0">
        <div className="container">
          {(result.isPreviousData || result.isLoading) && (
            <div style={{height: '25vh'}}>
              <Spinner type={'-relative'} />
            </div>
          )}
          {!result.isPreviousData && !result.isLoading && (
            <>
              {result.data?.data?.Fichero?.map((item) => {
                return (
                  <ElementCard
                    item={item}
                    key={item._idFichero}
                    setFileType={setFileType}
                    setSelected={setSelected}
                    refetch={result.refetch}
                    refreshStr={`file-list-${admSlug}-${orderField}-${orderType}-`}
                  />
                );
              })}
              <Pagination
                totalPages={totalPages ?? 1}
                currentPage={currentPage}
                generate={false}
                setPage={setCurrentPage}
              />
            </>
          )}
        </div>
      </div>
      <Viewer type={fileType} selected={selected} />
    </>
  );
}
