import {queryClient} from 'App';
import propTypes from 'prop-types';
import {useContext} from 'react';
import {ElementContext, FileListContext, EditFilesContext, EditContext} from './ElementCard';
import useSessionStorage from 'Hooks/useSessionStorage';

ButtonGroup.propTypes = {
  setIsLoading: propTypes.func.isRequired
};

async function fetchPublication(idMemento, typeQuery, setIsLoading) {
  let storedAuthToken = localStorage.getItem('token');
  let route = `/api/adm/Memento/${typeQuery}/${idMemento}`;
  setIsLoading(true);

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${storedAuthToken}`
    }
  };

  let resp = await fetch(route, requestOptions).then((res) => res.json());
  queryClient.refetchQueries().then(() => setIsLoading(false));

  return resp;
}

export default function ButtonGroup({setIsLoading}) {
  const element = useContext(ElementContext);
  const {edit, setEdit} = useContext(EditContext);
  const {getItem, toggleItem} = useSessionStorage('mementoOpened');
  const {showFiles, setShowFiles} = useContext(FileListContext);
  const {editFiles, setEditFiles} = useContext(EditFilesContext);
  const isTrashed = element?._indEliminado;

  const handleEdit = () => {
    toggleItem(element?._idMemento);
    setEdit(!edit);
    setShowFiles(false);
    setEditFiles(false);
  };

  const handleShowFiles = () => {
    setEdit(false);
    setShowFiles(!showFiles);
    setEditFiles(false);
  };

  const handleEditFiles = () => {
    setEdit(false);
    setShowFiles(false);
    setEditFiles(!editFiles);
    window.sessionStorage.setItem(`mementoLinkedFiles${element?._idMemento}`, JSON.stringify({}));
  };

  const handlePublish = (type) => {
    fetchPublication(element?._idMemento, type, setIsLoading);
  };

  const buttonPubli = () => {
    if (isTrashed != 1) {
      return (
        <>
          {element?._indPublicado == 1 && (
            <>
              <button
                className={`btn btn-outline-secondary ${showFiles ? 'active' : ''}`}
                title={`${showFiles ? 'Cerrar Descargas' : 'Ver Descargas'}`}
                onClick={() => handleShowFiles()}
              >
                <i className="bi bi-files"></i>{' '}
                {`${showFiles ? 'Cerrar Descargas' : 'Ver Descargas'}`}
              </button>
              <button
                className={`btn btn-outline-secondary ${editFiles ? 'active' : ''}`}
                title="Asociar Descarga"
                onClick={() => handleEditFiles()}
              >
                <i className="bi bi-file-plus"></i> Asociar Descarga
              </button>
              <button
                className="btn btn-outline-secondary"
                title="Despublicar"
                onClick={() => handlePublish('Unpublish')}
              >
                <i className="bi bi-eye-slash"></i> Despublicar
              </button>
              <a
                className="btn btn-outline-secondary"
                title="Ver el Memento"
                href={`/memento/${element?._slug}`}
                target="_blank"
                role="button"
              >
                <i className="bi bi-box-arrow-up-right"></i>
              </a>
            </>
          )}
          {element?._indPublicado != 1 && (
            <button
              className="btn btn-outline-secondary"
              title="Publicar"
              onClick={() => handlePublish('Publish')}
            >
              <i className="bi bi-eye"></i> Publicar
            </button>
          )}
          <button
            className="btn btn-outline-secondary"
            title="Borrar el Memento"
            onClick={() => handlePublish('Trash')}
          >
            <i className="bi bi-trash"></i>
          </button>
        </>
      );
    } else
      return (
        <button
          className="btn btn-outline-secondary"
          title="Borrar el Memento"
          onClick={() => handlePublish('Untrash')}
        >
          <i className="bi bi-arrow-clockwise"></i> Recuperar
        </button>
      );
  };

  return (
    <div className="btn-group flex-wrap">
      <button
        type="button"
        className={`btn btn-outline-secondary ${getItem(element?._idMemento) ? 'active' : ''}`}
        title="Editar el Memento"
        onClick={() => handleEdit()}
      >
        <i className="bi bi-pencil-square"></i>
      </button>
      {buttonPubli()}
    </div>
  );
}
