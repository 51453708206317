const FooterMenu = () => {
  const menu = {
    Corporativo: [
      {name: 'Lefebvre', url: '/conocenos/'},
      {name: 'Nuestro equipo', url: '/equipo-direccion/'},
      {name: 'Trabaja con nosotros', url: 'http://empleo.lefebvreelderecho.com/'},
      {name: 'Librerías asociadas', url: 'https://lefebvre.es/librerias-asociadas/'}
    ],
    Productos: [
      {name: 'Mementos', url: 'https://lefebvre.es/tienda/catalogo/mementos'},
      {name: 'Qmementos', url: 'https://lefebvre.es/tienda/bases-de-datos-juridicas/qmemento'},
      {name: 'Mementos de Autor', url: 'https://lefebvre.es/tienda/catalogo/manuales-derecho'},
      {name: 'Mementos Expertos', url: 'https://lefebvre.es/tienda/catalogo/mementos-expertos'},
      {name: 'Lex-ON', url: 'https://lefebvre.es/tienda/software/programa-gestion-despachos-abogados-lexon'}
    ],
    'Grupo Lefebvre': [
      {name: 'ELS', url: 'https://www.lefebvre-sarrut.eu/'},
      {name: 'El Derecho', url: 'https://elderecho.com/'},
      {name: 'Espacio Asesoría', url: 'https://www.espacioasesoria.com'},
      {name: 'Espacio Pymes', url: 'https://www.espaciopymes.com'}
    ]
  };

  return (
    <nav className="container-fluid corp-menu bg-f4 mt-5">
      <div className="container">
        <ul className="row">
          {Object.keys(menu).map((item, key) => {
            return (
              <li className="col-sm-3" key={key}>
                {item}
                <ul>
                  {menu[item].map((value, key) => {
                    return (
                      <li key={key}>
                        <a
                          rel="nofollow"
                          href={value.url}
                          target="_blank"
                          title="Enlace externo. Abre en ventana nueva"
                        >
                          {value.name}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })}
          <li className="col-sm-3">
            Contacto
            <ul>
              <li>Tel.: 91 210 80 00</li>
              <li>
                Mándanos un <a href="mailto:clientes@lefebvre.es">email</a>
              </li>
              <li>
                Monasterios de Suso y Yuso, 34
                <br />
                28049 Madrid
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default FooterMenu;
