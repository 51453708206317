import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import EditForm from './EditForm';
import MementoList from './MementoList';
import useSessionStorage from 'Hooks/useSessionStorage';
import {queryClient} from 'App';
import Spinner from 'Components/Common/Spinner';

ElementCard.propTypes = {
  item: PropTypes.object.isRequired,
  setSelected: PropTypes.func.isRequired,
  setFileType: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  refreshStr: PropTypes.string.isRequired
};

async function eliminateFile(item, refetch, refreshStr, setIsLoading) {
  let storedAuthToken = localStorage.getItem('token');
  setIsLoading(true);

  const requestOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${storedAuthToken}`,
      'Content-Type': 'application/json'
    }
  };

  await fetch(`/api/adm/File/Eliminate/${item._idFichero}`, requestOptions);
  refetch();
  queryClient.refetchQueries([refreshStr + 1]);
}

async function restoreFile(item, refetch, refreshStr, setIsLoading) {
  let storedAuthToken = localStorage.getItem('token');
  setIsLoading(true);

  const requestOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${storedAuthToken}`,
      'Content-Type': 'application/json'
    }
  };

  await fetch(`/api/adm/File/Restore/${item._idFichero}`, requestOptions);
  refetch();
  queryClient.refetchQueries([refreshStr + 2]);
}

export default function ElementCard({item, 
  // setSelected, 
  // setFileType, 
  refetch, refreshStr}) {
  const {getItem, toggleItem, removeItem} = useSessionStorage('fileOpened');
  const {toggleItem: toggleLinkedMemento} = useSessionStorage(
    `fileLinkedMemento${item._idFichero}`
  );
  const {getItem: getEdit, toggleItem: toggleEdit, removeItem: removeEdit} = useSessionStorage(
    'fileEdit'
  );
  const [isLoading, setIsLoading] = useState(false);
  const [edit, setEdit] = useState(getEdit(item?._idFichero));
  const [list, setList] = useState(getItem(item?._idFichero));
  let element = item?._listMementos?.Memento;
  if (element) element = Array.isArray(element) ? element : [element];
  else element = [];
  const numMementos = element.length;

  useEffect(() => {
    if (list) {
      sessionStorage.setItem(`fileLinkedMemento${item._idFichero}`, JSON.stringify({}));
      element.forEach((item) => {
        toggleLinkedMemento(item._idMemento);
      });
    } else sessionStorage.removeItem(`fileLinkedMemento${item._idFichero}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  // const handleClick = (e, item) => {
  //   e.preventDefault();
  //   setSelected(item);
  //   setFileType(item.type);
  // };

  // const handleTarget = (type) => {
  //   if (type != 'image') return '#modalContent';
  // };

  const handleEliminate = () => {
    setList(false);
    setEdit(false);
    removeItem(item._idFichero);
    removeEdit(item._idFichero);
    eliminateFile(item, refetch, refreshStr, setIsLoading);
  };

  const handleRestore = () => {
    restoreFile(item, refetch, refreshStr, setIsLoading);
  };

  return (
    <div className="card border-light my-2">
      <div
        className="card-header border-0 align-items-center p-1"
        style={{backgroundColor: '#cdc'}}
      >
        <h5 className="pl-3 m-0">{item._titulo}</h5>
        <p className="pl-3 m-0" style={{color: '#666'}}>
          {item._descripcion}
        </p>
      </div>
      <div className="media position-relative" style={{backgroundColor: '#f0f4f0'}}>
        <span className={`${item.iconCss} file-type my-2 ml-3`}></span>
        <label className="form-check-label m-3" htmlFor="flexSwitchCheckDefault">
          {!isLoading && item.fileName}
          {isLoading && (
            <Spinner
              type={'-relative'}
              margin={'position-absolute bottom-0 start-50 translate-middle-x'}
            />
          )}
        </label>
        {item._indEliminado == 1 && (
          <div className="btn-group ml-auto m-2">
            <button
              className="btn btn-outline-secondary"
              title="Borrar el Memento"
              onClick={() => handleRestore()}
            >
              <i className="bi bi-arrow-clockwise"></i> Recuperar
            </button>
          </div>
        )}
        {!item._indEliminado && (
          <div className="btn-group ml-auto m-2">
            <button
              type="button"
              className={`btn btn-outline-secondary`}
              title="Ver Mementos asociados"
              onClick={() => {
                setList(!list);
                toggleItem(item._idFichero);
              }}
            >
              Mementos{' '}
              <span className="badge rounded-pill bg-secondary px-2 py-1">{numMementos}</span>
            </button>
            <button
              type="button"
              className={`btn btn-outline-secondary`}
              title="Editar la Descarga"
              onClick={() => {
                setEdit(!edit);
                toggleEdit(item._idFichero);
              }}
            >
              <i className="bi bi-pencil-square"></i>
            </button>
            {/* {['image', 'doc'].indexOf(item.type) > -1 && ( */}
              <a
                href={item.url}
                target="_blank"
                className="btn btn-outline-secondary"
                title="Ver la Descarga"
                role="button"
              >
                <i className="bi bi-box-arrow-up-right"></i>
              </a>
            {/* )}
            {['pdf', 'html'].indexOf(item.type) > -1 && (
              <a
                href="#"
                className="btn btn-outline-secondary"
                data-toggle="modal"
                title="Ver la Descarga"
                role="button"
                data-target={handleTarget(item.type)}
                onClick={(e) => handleClick(e, item)}
              >
                <i className="bi bi-box-arrow-up-right"></i>
              </a>
            )} */}
            <button
              type="button"
              className={`btn btn-outline-secondary`}
              title="Borrar la Descarga"
              onClick={() => handleEliminate()}
            >
              <i className="bi bi-trash-fill"></i>
            </button>
          </div>
        )}
      </div>
      {edit && !item._indEliminado && <EditForm setEdit={setEdit} item={item} refetch={refetch} />}
      {list && !item._indEliminado && (
        <MementoList setList={setList} item={item} refetch={refetch} />
      )}
    </div>
  );
}
