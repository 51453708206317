import {useContext, useState} from 'react';
import {useQuery} from 'react-query';
import {ElementContext, FileListContext} from './ElementCard';
import Spinner from 'Components/Common/Spinner';

function unlinkFile(element, item, refetch, setIsFetching) {
  let storedAuthToken = localStorage.getItem('token');
  let idMemento = element._idMemento;
  let idFile = item._idFichero;
  setIsFetching(true);

  let params = {
    idMemento: idMemento,
    idFile: idFile
  };

  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${storedAuthToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  };

  fetch('/api/adm/Memento/UnlinkFile', requestOptions).then(() =>
    refetch().then(() => setIsFetching(false))
  );
}

export default function FilesList() {
  const {setShowFiles} = useContext(FileListContext);
  const element = useContext(ElementContext);
  const [isFetching, setIsFetching] = useState(false);
  const {data, isLoading, refetch} = useQuery(`memento/files/${element?._idMemento}`, () =>
    fetch(`/api/Memento/Files/${element?._idMemento}`)
      .then((res) => res.json())
      .then((data) => data.data.Fichero)
  );

  const handleUnlink = (item) => {
    unlinkFile(element, item, refetch, setIsFetching);
  };

  return (
    <div className="card-body p-2" style={{backgroundColor: '#e6eae6'}}>
      <button
        className="btn-close float-right"
        aria-label="Cerrar"
        onClick={() => setShowFiles(false)}
      ></button>
      <div className="container m-3">
        <h5 className="p-2">Descargas Recomendadas</h5>
        {(isLoading || isFetching) && <Spinner type={'-relative'} />}
        {!isLoading &&
          !isFetching &&
          data?.map((item, key) => {
            return (
              <div className="card mb-3 mr-3" key={key}>
                <div className="card-header py-1">
                  <h5 className="mb-0">{item._titulo}</h5>
                  <p className="m-0" style={{color: '#666'}}>
                    {item._descripcion}
                  </p>
                </div>
                <div className="media ml-3">
                  <span className={`${item.iconCss} file-type m-2`}></span>
                  <div className="media-body d-flex">
                    <p className="m-3">{item.fileName}</p>
                    <button
                      className="btn btn-outline-secondary border-0 ml-auto py-0 m-2"
                      title="Desvincular la Descarga"
                      onClick={() => handleUnlink(item)}
                    >
                      <i className="bi bi-backspace-fill"></i>
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
