import propTypes from 'prop-types';

Viewer.propTypes = {
  type: propTypes.string.isRequired,
  selected: propTypes.object.isRequired
};

export default function Viewer({type, selected}) {
  return (
    <div
      className="modal fade"
      id="modalContent"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalContentTitle"
      aria-hidden="true"
      key={Math.random()}
    >
      <div className="modal-dialog modal-xl" role="document">
        <div className="modal-content">
          <div className="modal-header flex-column">
            <button className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <h5 className="modal-title" id="modalContentTitle">
              {selected?._titulo}
            </h5>
            <p className="page-subtitle">{selected?._descripcion}</p>
          </div>
          <div className="modal-body">
            {type == 'pdf' && (
              <embed
                className="pdfobject"
                src={selected?.url}
                type="application/pdf"
                title={selected?._nombre}
                internalinstanceid="5"
                sub-title={selected?._nombre}
              />
            )}
            {type == 'html' && (
              <iframe
                id="iframe-docs"
                src={selected?.url}
                className="htmlviewer"
                load="replaceImages();"
              ></iframe>
            )}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
