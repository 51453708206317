import PropTypes from 'prop-types';
import useSessionStorage from 'Hooks/useSessionStorage';
import Form from './Form';

EditForm.propTypes = {
  setEdit: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired
};

export default function EditForm({setEdit, item, refetch}) {
  const {removeItem} = useSessionStorage('fileEdit');

  const handleClick = () => {
    removeItem(item._idFichero);
    setEdit(false);
  };

  return (
    <div className="card-body p-2" style={{backgroundColor: '#e6eae6'}}>
      <Form handleClose={handleClick} element={item} refetch={refetch} />
    </div>
  );
}
