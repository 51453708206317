import {useState, createContext} from 'react';
import MementosPanel from './Components/MementosPanel/MementosPanel';
import DownloadsPanel from './Components/DownloadsPanel/DownloadsPanel';

export const ResponseContext = createContext();

export default function AdminPanel() {
  const [tab, setTab] = useState(0);
  const tabs = ['Gestión de Mementos', 'Gestión de Descargas'];
  const tabComponents = [
    <>
      <MementosPanel />
    </>,
    <>
      <DownloadsPanel />
    </>
  ];

  const handleClick = (e, value) => {
    e.preventDefault();
    if (tab != value) setTab(value);
    sessionStorage.setItem('fileOpened', JSON.stringify({}));
    sessionStorage.setItem('fileEdit', JSON.stringify({}));
  };

  const loopTabs = () => {
    let arr = [];

    for (let i = 0; i < tabs.length; i++) {
      let active = tab === i ? 'active' : '';
      arr.push(
        <li className="nav-item" key={i}>
          <a className={`nav-link ${active}`} onClick={(e) => handleClick(e, i)} href="#">
            {tabs[i]}
          </a>
        </li>
      );
    }

    return arr;
  };

  return (
    <div className="card border-0">
      <div className="card-header border-bottom d-flex justify-content-center">
        <ul className="nav nav-tabs card-header-tabs mt-0">{loopTabs()}</ul>
      </div>
      {tabComponents[tab]}
    </div>
  );
}
