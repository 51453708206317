import {useHistory} from 'react-router-dom';
import {useContext, useEffect} from 'react';
import {FilterContext, AreaContext, SearchContext, CurrentPageContext, AllAreasContext} from 'App';

export default function GenerateUrl() {
  const history = useHistory();
  const {filter} = useContext(FilterContext);
  const {area} = useContext(AreaContext);
  const {search} = useContext(SearchContext);
  const {currentPage} = useContext(CurrentPageContext);
  const {allAreas} = useContext(AllAreasContext);

  useEffect(() => {
    if (allAreas) {
      let url = '';
      if (filter?.length && filter !== 'todos') url += `/mementos/${filter}`;
      if (area?._slug && area?._slug !== 'todas') url += `/area/${area?._slug}`;
      if (search?.length > 2) url += `/buscar/${search}`;
      if (currentPage > 1) url += `/${currentPage}`;
      history.push(url);
    }
    // eslint-disable-next-line
  }, [filter, area, search, currentPage, allAreas]);

  return <></>;
}
