import {useState, useEffect} from 'react';
import propTypes from 'prop-types';
import {useQuery} from 'react-query';
import Spinner from 'Components/Common/Spinner';

Updates.propTypes = {
  mementoCode: propTypes.string,
  shopUrl: propTypes.string,
  mementoTitle: propTypes.string,
  setHasUpdates: propTypes.func.isRequired
};

export default function Updates({mementoCode, mementoTitle, setHasUpdates}) {
  const [files, setFiles] = useState([]);
  const [search, setSearch] = useState();
  const [element, setElement] = useState('');
  const [input, setInput] = useState(0);
  const [selected, setSelected] = useState([]);
  const {data, isLoading, isError} = useQuery(`memento/updates/${mementoCode}`, () =>
    fetch(`/api/Memento/Updates/${mementoCode}`).then((res) => res.json())
  );

  useEffect(() => {
    if (!isLoading) setFiles(data);
    if (!isLoading && data.length) setHasUpdates(true);
    // eslint-disable-next-line
  }, [data, isLoading]);

  useEffect(() => {
    let arr = files?.filter((item) => {
      let marginals = item.MARGINAL.split(',');
      let aux = marginals.find((element) => search && !(element + '').localeCompare(search));
      if (aux) return item;
    });
    if (arr && !Array.isArray(arr)) setSelected([arr]);
    else setSelected(arr);
    // eslint-disable-next-line
  }, [search]);

  const handleChange = (e) => {
    setInput(e.target.value);
    setSearch();
  };

  const handleSubmit = (e) => {
    setSelected([]);
    e.preventDefault();
    setSearch(input);
    setElement(input);
  };

  const handleMarginalClick = (e, element, item) => {
    e.preventDefault();
    setSelected([item]);
    setElement(element);
  };

  const parseMarginalCodes = (marginals, item) => {
    let arr = [];

    marginals.forEach((element, key) => {
      arr.push(
        <a
          href="#"
          className="marginal-number"
          key={key}
          onClick={(e) => handleMarginalClick(e, element, item)}
        >
          {element}
        </a>
      );
    });

    return arr;
  };

  const parseMarginals = () => {
    let arr = [];

    files?.map((item, key) => {
      let marginals = item.MARGINAL.split(',');
      let marginalCodes = parseMarginalCodes(marginals, item);
      if (marginalCodes.length)
        arr.push(
          <div className="content-row" key={key}>
            <div className="col-12 col-md-3 col-lg-2 pb-0 pb-md-3">{marginalCodes}</div>
            <div className="col-12 col-md-9 col-lg-10 pt-0 pt-md-3">{item.TITULO}</div>
          </div>
        );
    });
    if (!arr.length)
      return (
        <>
          <div className="data-table my-3 mr-4">
            <div className="alert alert-danger col-lg-8" role="alert">
              Este marginal no ha sufrido ninguna actualización
            </div>
          </div>
        </>
      );

    return (
      <>
        <div className="data-table col-12">
          <div className="row head-content py-3">
            <div className="col-12 col-md-3 col-lg-2">Marginal</div>
            <div className="col-12 col-md-9 col-lg-10">Descripción</div>
          </div>
          <div className="row scroll-content">{arr}</div>
        </div>
      </>
    );
  };

  const loopSelected = () => {
    return (
      <div className="col-12 pl-0 mt-4">
        <p>
          <strong>
            Hemos encontrados las siguientes entradas del {mementoTitle} que han sido actualizados.{' '}
            {element}:
          </strong>
        </p>
        <hr />
        {selected.map((item, key) => (
          <div className="col-12  pl-0 marginal-info bbottom-1 mb-3" key={key}>
            <p className="date">Fecha: {item.FECHA}</p>
            <p className="title">
              Marginal {element}: {item.TITULO}
            </p>
            <p className="info">{item.RESUMEN}</p>
          </div>
        ))}
      </div>
    );
  };

  /**
   * Spinner de carga
   */
  if (isLoading)
    return (
      <div style={{height: '25vh'}}>
        <Spinner type={'-relative'} />
      </div>
    );

  return (
    <>
      <p className="tab-info mt-4">
        Para verificar si el contenido completo de un párrafo de tu Memento contiene alguna
        actualización, por favor, introduce el número marginal (es el número que figura en el margen
        del párrafo del Memento) para realizar tu consulta.
      </p>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="input-group col-lg-8 pl-0 pr-0 pr-md-3">
          <input
            id="search-marginal"
            type="text"
            pattern="^[0-9][0-9,\.]{0,10}"
            className="form-control search-input"
            placeholder="Inserta aquí el número de marginal de tu Memento. Utiliza sólo números y el símbolo de punto"
            aria-label="Cadena de búsqueda"
            aria-describedby="sbutton"
            onChange={(e) => handleChange(e)}
          />
          <div className="input-group-append">
            <button className="btn btn-primary search-marginal" type="submit" id="sbutton">
              <span className="lf-icon-search"></span>
            </button>
          </div>
        </div>
      </form>

      {selected?.length > 0 && loopSelected()}
      {!isError && parseMarginals()}
    </>
  );
}
