import propTypes from 'prop-types';
import {useContext, useState, useEffect} from 'react';
import Spinner from 'Components/Common/Spinner';
import {AllAreasContext, queryClient} from 'App';

Form.propTypes = {
  element: propTypes.object,
  handleClose: propTypes.func.isRequired
};

const handle = (res, setIsFetching, setData, file) => {
  if (!file) {
    queryClient.refetchQueries();
    setIsFetching(false);
    setData(res);
  }
};

function saveChanges(
  id,
  desc,
  code,
  year,
  selectedAreas,
  prevAreas,
  image,
  setIsFetching,
  setData
) {
  let storedAuthToken = localStorage.getItem('token');
  let areas = selectedAreas?.map((item) => item._idMateria) ?? [];
  let prev = prevAreas?.map((item) => item._idMateria) ?? [];
  let params = {
    description: desc,
    codProducto: code,
    year: year,
    areas: areas,
    prev: prev,
    image: image?.name ?? image
  };
  if (id) params.idMemento = id;

  if (image?.size > 16000000) {
    setIsFetching(false);
    return setData({code: 413, message: 'La imagen es demasiado grande'});
  }

  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${storedAuthToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  };

  fetch('/api/adm/Memento/Change', requestOptions)
    .then((res) => res.json())
    .then((res) => handle(res, setIsFetching, setData, image?.name));
  if (image?.name) {
    let data = new FormData();
    data.append('imageFile', image);
    requestOptions.body = data;
    delete requestOptions.headers['Content-Type'];
    fetch('/api/adm/File/UploadImage', requestOptions)
      .then((res) => res.json())
      .then((res) => {
        queryClient.refetchQueries();
        setIsFetching(false);
        setData(res);
      });
  }
}

function getElementAreas(element) {
  let areas = element?._listMaterias?.Materia;
  if (!areas) return [];
  if (Array.isArray(areas)) return areas;
  else return [areas];
}

export default function Form({element, handleClose}) {
  const hasElement = element ? true : false;
  const {allAreas} = useContext(AllAreasContext);
  const [isFetching, setIsFetching] = useState(false);
  const [response, setResponse] = useState({code: 0, message: ''});
  const [descValue, setDescValue] = useState(element?._descripcion ?? '');
  const [codeValue, setCodeValue] = useState(element?._codigoMemento ?? '');
  const [imgValue, setImgValue] = useState(element?._imagen ?? '');
  const [yearValue, setYearValue] = useState(element?._anno ?? '');
  let prevAreas = getElementAreas(element);
  const [selectedAreas, setSelectedAreas] = useState(prevAreas);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!isFetching) setResponse({code: data?.code, message: data?.message});
    // eslint-disable-next-line
  }, [isFetching, data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsFetching(true);
    saveChanges(
      element?._idMemento,
      descValue,
      codeValue,
      yearValue,
      selectedAreas,
      prevAreas,
      imgValue,
      setIsFetching,
      setData
    );
  };

  const removeArea = (e, item) => {
    e.preventDefault();
    let arr = selectedAreas.filter((elem) => elem._idMateria != item._idMateria);

    setSelectedAreas(arr);
  };

  const addArea = (e, item) => {
    e.preventDefault();
    let arr = Array.from(selectedAreas);
    arr.push(item);

    setSelectedAreas(arr);
  };

  const handleImageChange = (e) => {
    setImgValue(e.target.files[0]);
  };

  const unsetResponse = () => {
    setResponse({code: 0, message: ''});
  };

  if (isFetching)
    return (
      <div style={{height: '25vh'}}>
        <Spinner type={'-relative'} />
      </div>
    );

  return (
    <>
      <button
        className="btn-close float-right"
        aria-label="Cerrar"
        onClick={() => handleClose(false)}
      ></button>
      <div className="container m-3">
        <h5 className="p-2">{hasElement ? 'Edición' : 'Creación'} del Memento</h5>

        {response.code > 0 && response.code && (
          <div
            className={`alert alert-dismissible fade show mr-3 ${
              response.code == 200 ? 'alert-success' : 'alert-danger'
            }`}
            role="alert"
          >
            {response.message}
          </div>
        )}

        {((response.code != 200 && !hasElement) || hasElement) && (
          <form onSubmit={(e) => handleSubmit(e)} onClick={() => unsetResponse()}>
            <div className="form-floating mb-3 mr-3">
              <input
                type="text"
                className="form-control"
                id="floatingInputTitle"
                value={descValue}
                required
                onChange={(e) => setDescValue(e.target.value)}
              />
              <label htmlFor="floatingInputTitle">Título</label>
            </div>
            <div className="input-group mb-3">
              <button
                className="input-group-text dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Áreas
              </button>
              <ul className="dropdown-menu">
                {allAreas?.map((item) => {
                  if (
                    !selectedAreas?.some((e) => e._idMateria === item._idMateria) &&
                    item._idMateria !== -1
                  )
                    return (
                      <li key={item._idMateria}>
                        <a className="dropdown-item" href="#" onClick={(e) => addArea(e, item)}>
                          {item._descMateria}
                        </a>
                      </li>
                    );
                })}
              </ul>
              <span className="form-control mr-3">
                {selectedAreas?.map((item) => {
                  return (
                    <input
                      type="button"
                      value={item?._descMateria}
                      name={item?._descMateria}
                      className="btn py-0 px-1 btn-outline-danger mr-2"
                      key={item?._idMateria}
                      onMouseEnter={(e) => {
                        e.target.value += ' ✖';
                      }}
                      onMouseLeave={(e) => {
                        e.target.value = e.target.name;
                      }}
                      onClick={(e) => removeArea(e, item)}
                    />
                  );
                })}
              </span>
            </div>
            <div className="form-floating mb-3 mr-3">
              <input
                type="text"
                className="form-control"
                id="floatingInputCode"
                value={codeValue}
                required
                onChange={(e) => setCodeValue(e.target.value.toUpperCase())}
              />
              <label htmlFor="floatingInputCode">Código</label>
            </div>
            <div className="form-floating mb-3 mr-3">
              <input
                type="text"
                className="form-control"
                id="floatingInputYear"
                value={yearValue}
                onChange={(e) => setYearValue(e.target.value)}
                maxLength="9"
                pattern="^\d{4}(-\d{4})?"
                required
              />
              <label htmlFor="floatingInputYear">Año</label>
            </div>
            <div className="input-group mb-3 mr-3">
              <label className="input-group-text" htmlFor="inputGroupFile">
                Imagen
              </label>
              <input
                type="file"
                className="form-control mr-3"
                id="inputGroupFile"
                required={!element}
                onChange={(e) => handleImageChange(e)}
                accept="image/*"
              />
            </div>
            <div className="alert alert-primary mr-3 py-1" role="alert">
              Tamaño máximo de subida de imagenes: <span className="fw-bold">16 Mb</span>
            </div>
            <div className="d-grid mx-auto">
              <button className="btn btn-success mr-3">Enviar</button>
            </div>
          </form>
        )}
      </div>
    </>
  );
}
