import {useQueries} from 'react-query';
import {useContext, useState, useEffect} from 'react';
import {AreaContext, FilterContext, SearchContext, CurrentPageContext} from 'App';
import Spinner from 'Components/Common/Spinner';
import {fetchMementos} from 'Components/Common/Functions';
import Show from './Components/Show';
import {PAGE_LIMIT, FILTER} from 'define';

export default function List() {
  const {filter} = useContext(FilterContext);
  const {area} = useContext(AreaContext);
  const {search} = useContext(SearchContext);
  const {currentPage} = useContext(CurrentPageContext);
  const [totalPages, setTotalPages] = useState(1);
  const [memento, setMemento] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [numElements, setNumElements] = useState(0);
  const [message, setMessage] = useState(<br />);
  const results = useQueries([
    {
      queryKey: [`mementos/${area?._slug}/${filter}/${search}`, currentPage],
      queryFn: fetchMementos({
        currentPage: currentPage,
        area: area,
        filterSlug: filter,
        search: search
      })
    },
    {
      queryKey: [`mementos/${area?._slug}/${filter}/${search}`, currentPage + 1],
      queryFn: fetchMementos({
        currentPage: currentPage + 1,
        area: area,
        filterSlug: filter,
        search: search
      })
    }
  ]);
  const result = results[0];

  /**
   * Contar el número de páginas
   */
  useEffect(() => {
    if (!result.isLoading) {
      let total = result.data.result._IntTotalRecs;
      let totalPages = Math.ceil(total / PAGE_LIMIT);
      setTotalPages(total && totalPages);
      setNumElements(result.data.data.Memento ? result.data.data.Memento.length : 0);
      setMemento(result.data.data.Memento);
      setTotalElements(total);
    }
    // eslint-disable-next-line
  }, [result.isLoading, result.data]);

  useEffect(() => {
    if (search?.length) {
      let filterValue = FILTER.find((item) => item.slug === filter)?.value;

      setMessage(
        <div className="col">
          <h2 className="page-title">
            {totalElements} resultados para la búsqueda:{' '}
            <span className="searched-text">{search}</span>
          </h2>
          {((filter && filter !== 'todos') || area) && (
            <p className="text-filters page-subtitle">
              Resultados filtrados por:{' '}
              {filter && <span className="filter-date">{filterValue}</span>}
              {filter && area && <span> | </span>}
              {area && <span className="filter-area">{area?._descMateria}</span>}
            </p>
          )}
        </div>
      );
    } else {
      setMessage(
        <div className="col">
          <h2 className="page-title">Extras Mementos en papel</h2>
          {memento?.length === 0 && (
            <h3 className="page-title">
              No hay resultados
            </h3>
          )}
          {memento?.length !== 0 && (
            <p className="page-subtitle">
              Selecciona el Memento que quieres para acceder a sus actualizaciones resumidas y/o
              descargas recomendadas.
            </p>
          )}
        </div>
      );
    }
    // eslint-disable-next-line
  }, [area, filter, search, numElements]);

  /**
   * Spinner de carga
   */
  if (result.isPreviousData || result.isLoading)
    return (
      <div style={{height: '25vh'}}>
        <Spinner type={'-relative'} />
      </div>
    );

  return (
    <Show
      data={memento}
      message={message}
      totalPages={totalPages}
      currentPage={currentPage}
      numElements={numElements}
    />
  );
}
