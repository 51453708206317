import {useState, useEffect, useContext} from 'react';
import {useQueries} from 'react-query';
import {fetchMementos} from 'Components/Common/Functions';
import Spinner from 'Components/Common/Spinner';
import Pagination from 'Components/Common/Pagination';
import ElementCard from './Components/ElementCard';
import CreateForm from './Components/CreateForm';
import {CurrentPageContext, AuthContext, AdmSlugContext, queryClient} from 'App';

export default function MementosPanel() {
  const {setIsLogged} = useContext(AuthContext);
  const [totalPages, setTotalPages] = useState(1);
  const [numElements, setNumElements] = useState(0);
  const [create, setCreate] = useState(false);
  const [memento, setMemento] = useState([]);
  const {admSlug, setAdmSlug} = useContext(AdmSlugContext);
  const [filterPublish, setFilterPublish] = useState(0);
  const [filterTrash, setFilterTrash] = useState(2);
  const {currentPage, setCurrentPage} = useContext(CurrentPageContext);
  const results = useQueries([
    {
      queryKey: [`mementos-adm-${filterPublish}-${filterTrash}-${admSlug}`, currentPage],
      queryFn: fetchMementos({
        currentPage: currentPage,
        indPublish: filterPublish,
        url: '/api/adm/Memento/List',
        indRemoved: filterTrash,
        search: admSlug
      })
    },
    {
      queryKey: [`mementos-adm-${filterPublish}-${filterTrash}-${admSlug}`, currentPage + 1],
      queryFn: fetchMementos({
        currentPage: currentPage + 1,
        indPublish: filterPublish,
        url: '/api/adm/Memento/List',
        indRemoved: filterTrash,
        search: admSlug
      })
    }
  ]);
  const result = results[0];

  useEffect(() => {
    if (!result.isLoading && result.data?.code == 401) {
      result.data.code = null;
      localStorage.removeItem('token');
      setIsLogged(false);
    }
    if (!result.isLoading) {
      sessionStorage.setItem('mementoOpened', JSON.stringify({}));
      let total = result.data.result._IntTotalRecs;
      let totalPages = Math.ceil(total / 10);
      setTotalPages(total && totalPages);
      setNumElements(result.data.data.Memento ? result.data.data.Memento.length : 0);
      setMemento(result.data.data.Memento);
    }
    // eslint-disable-next-line
  }, [result.isLoading, result.data]);

  const handleClickFilter = (filter) => {
    setFilterPublish(filter);
    setFilterTrash(2);
    setCurrentPage(1);
  };

  const handleClickTrash = () => {
    setFilterPublish(0);
    setFilterTrash(1);
    setCurrentPage(1);
  };

  const loopElements = () => {
    let arr = [];

    for (let i = 0; i < numElements; i++) {
      arr.push(<ElementCard element={memento[i]} key={memento[i]._idMemento} />);
    }

    return arr;
  };

  return (
    <>
      {/* Header of Tab */}
      <div className="card-body border-bottom p-2">
        <div className="container d-flex align-items-center">
          <h4 className="mr-auto">Gestión de Mementos</h4>
          <div className="btn-group flex-wrap">
            <button
              type="button"
              className="btn btn-success"
              title="Añadir Memento"
              onClick={() => setCreate(!create)}
            >
              {!create && <i className="bi bi-plus"></i>}{' '}
              {`${create ? 'Cerrar formulario' : 'Añadir Memento'}`}
            </button>
            <button
              type="button"
              className={`btn btn-outline-secondary ${
                filterPublish == 0 && filterTrash == 2 ? 'active' : ''
              }`}
              title="Ver Descargas"
              onClick={() => handleClickFilter(0)}
            >
              <i className="bi bi-list-check"></i> Ver Todos
            </button>
            <button
              type="button"
              className={`btn btn-outline-secondary ${filterPublish == 1 ? 'active' : ''}`}
              title="Asociar Descarga"
              onClick={() => handleClickFilter(1)}
            >
              <i className="bi bi-eye-fill"></i> Publicados
            </button>
            <button
              type="button"
              className={`btn btn-outline-secondary ${filterPublish == 2 ? 'active' : ''}`}
              title="Despublicar"
              onClick={() => handleClickFilter(2)}
            >
              <i className="bi bi-eye-slash-fill"></i> Despublicados
            </button>
            <button
              type="button"
              className={`btn btn-outline-secondary ${filterTrash == 1 ? 'active' : ''}`}
              title="Papelera"
              onClick={() => handleClickTrash()}
            >
              <i className="bi bi-trash-fill"></i> Papelera
            </button>
          </div>
        </div>
        {admSlug != '' && (
          <div className="container d-flex">
            <h5 className="pt-2">
              <strong>Contiene:</strong> {admSlug}
            </h5>
            <button
              className="btn btn-outline-secondary border-0 ml-5"
              onClick={() => {
                setAdmSlug('');
                queryClient.refetchQueries();
              }}
            >
              <i className="bi bi-backspace-fill"></i>
            </button>
          </div>
        )}
        {create && <CreateForm handleClick={setCreate} />}
      </div>
      {/* End of Header of Tab */}
      {/* Content of Tab */}
      <div className="card-body p-0">
        <div className="container">
          {/* Card of Memento */}
          {(result.isPreviousData || result.isLoading) && (
            <div style={{height: '25vh'}}>
              <Spinner type={'-relative'} />
            </div>
          )}
          {!result.isPreviousData && !result.isLoading && (
            <>
              {loopElements()}
              <Pagination totalPages={totalPages} currentPage={currentPage} generate={false} />
            </>
          )}
          {/* End of Card of Memento */}
        </div>
      </div>
      {/* End of Content of Tab */}
    </>
  );
}
