import propTypes from 'prop-types';
import {useContext} from 'react';
import {FilterContext, SearchContext, CurrentPageContext} from 'App';
import {FILTER} from 'define';

HeaderDateSelect.propTypes = {
  isMobile: propTypes.bool,
  toggleGenerate: propTypes.func
};

export default function HeaderDateSelect({isMobile, toggleGenerate}) {
  const {setFilter} = useContext(FilterContext);
  const {setSearch} = useContext(SearchContext);
  const {setCurrentPage} = useContext(CurrentPageContext);

  const handleClick = (item) => {
    let selected = FILTER.find((element) => element.value === (item?.value ?? item))?.slug;
    setFilter(selected);
    toggleGenerate();
    setSearch('');
    setCurrentPage(1);
  };

  if (isMobile === true)
    return (
      <select id="rango" className="form-control" onChange={(e) => handleClick(e.target.value)}>
        {FILTER.map((item) => {
          return (
            <option value={item.value} key={item.id}>
              {item.value}
            </option>
          );
        })}
      </select>
    );

  return (
    <div className="dropdown-menu dd-fechas">
      {FILTER.map((item) => {
        return (
          <a
            className="dropdown-item"
            key={item.id}
            onClick={() => handleClick(item)}
            style={{cursor: 'pointer'}}
          >
            {item.value}
          </a>
        );
      })}
    </div>
  );
}
