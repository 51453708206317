import {useState} from 'react';
import PropTypes from 'prop-types';

MementoElement.propTypes = {
  item: PropTypes.object.isRequired,
  element: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired
};

async function unlinkFile(item, element, refetch) {
  let storedAuthToken = localStorage.getItem('token');
  let idMemento = item._idMemento;
  let idFile = element._idFichero;

  let params = {
    idMemento: idMemento,
    idFile: idFile
  };

  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${storedAuthToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  };

  await fetch('/api/adm/Memento/UnlinkFile', requestOptions);
  refetch();
}

export default function MementoElement({item, element, refetch}) {
  const [hover, setHover] = useState(false);

  const handleClick = () => {
    unlinkFile(item, element, refetch);
  };

  return (
    <div className="col" style={{minHeight: '303px'}}>
      <button
        className="h-100 btn btn-outline-secondary p-0 border-0"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        data-bs-toggle="modal"
        data-bs-target={`#unlinkMementoModal${element._idFichero}${item._idMemento}`}
      >
        <div className="card h-100 bg-transparent position-relative">
          {hover && (
            <div className="position-absolute top-50 start-50 translate-middle">
              <i className="bi bi-x-circle-fill text-danger" style={{fontSize: '3em'}}></i>
              <span className="badge bg-light text-dark">Eliminar</span>
            </div>
          )}
          <img src={item.image} className="card-img-top" alt={item._descripcion} />
          <div className="card-body p-0 d-float">
            <p className="card-text text-center">{item._descripcion}</p>
          </div>
        </div>
      </button>
      <div
        className="modal fade"
        id={`unlinkMementoModal${element._idFichero}${item._idMemento}`}
        tabIndex="-1"
        aria-labelledby="unlinkMementoModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="unlinkMementoModalLabel">
                Confirmar eliminación
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                Cancelar
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={() => handleClick()}
              >
                Eliminar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
