import {useState, useEffect} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useQuery} from 'react-query';
import Updates from './Components/Updates';
import Downloads from './Components/Downloads';
import Spinner from 'Components/Common/Spinner';
//import {SHOP_URL} from 'define';

function setShopLink(area) {
  switch (area) {
    /*case 2:
      return `${SHOP_URL}/actum-social`;
    case 6:
      return `${SHOP_URL}/actum-inmobiliario-y-urbanismo`;
    case 3:
    case 4:
    case 5:
    case 7:
      return `${SHOP_URL}/actum-mercantil-y-contable`;
    default:
      return `${SHOP_URL}/actum-fiscal`;*/
    default:
      return `https://lefebvre.es/tienda/actualidad-juridica/actum-actualidad-mementos`;
  }
}

const Details = () => {
  const history = useHistory();
  const {slug} = useParams();
  const [selectedTab, setSelectedTab] = useState([false, false]);
  const [hasUpdates, setHasUpdates] = useState(false);
  const [hasDownloads, setHasDownloads] = useState(false);
  const {data, isLoading} = useQuery(
    `memento/${slug}`,
    () =>
      fetch(`/api/Memento/Get/${slug}`)
        .then((res) => res.json())
        .then((data) => {
          return data.data.Memento;
        }),
    {keepPreviousData: true}
  );

  useEffect(() => {
    if (hasUpdates) setSelectedTab([true, false]);
    else if (hasDownloads) setSelectedTab([false, true]);
  }, [hasUpdates, hasDownloads]);

  useEffect(() => {
    //Hay que inicializarlo una vez que se haya resuelto la peticion porque hasta entonces no existe el div
    if (data) {
      window.googletag.cmd.push(function() { 
        window.googletag.display('div-gpt-ad-1689751321019-0');
        window.googletag.pubads().refresh(); 
      }); 
    }
  }, [data]);

  if (slug === null) history.push('/');

  /**
   * Spinner de carga
   */
  if (isLoading)
    return (
      <div style={{height: '25vh'}}>
        <Spinner type={'-relative'} />
      </div>
    );

  const changeSelectedTab = (tab) => {
    if (!selectedTab[tab]) setSelectedTab([!selectedTab[0], !selectedTab[1]]);
  };

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="mx-auto m-md-0 col-6 col-md-2">
          <img src={data?.image} className="img-responsive" />
        </div>
        <div className="col-12 col-md">
          <h1 className="titulo-memento text-center text-md-left mt-3 mt-md-0">
            {data?._descripcion}
          </h1>

          <ul className="nav nav-tabs" id="mementoTabs">
            {hasUpdates && (
              <li className="nav-item">
                <a
                  className={`nav-link ${selectedTab[0] && 'active'}`}
                  id="updates-tab"
                  onClick={() => changeSelectedTab(0)}
                  style={{cursor: 'pointer'}}
                >
                  Actualizaciones resumidas
                </a>
              </li>
            )}
            {hasDownloads && (
              <li className="nav-item">
                <a
                  className={`nav-link ${selectedTab[1] && 'active'}`}
                  id="downlodas-tab"
                  onClick={() => changeSelectedTab(1)}
                  style={{cursor: 'pointer'}}
                >
                  Descargas recomendadas
                </a>
              </li>
            )}
          </ul>
          <div className="tab-content" id="mementoTabsContent">
            <div id='div-gpt-ad-1689751321019-0' style={{ minWidth: 920 + 'px', minHeight: 150 + 'px' }}></div>
            <div className={`tab-pane fade show ${selectedTab[0] && 'active'}`} id="updates">
              <Updates
                mementoCode={data?._codigoMemento}
                shopUrl={setShopLink(data?._listMaterias.Materia._idMateria)}
                mementoTitle={data?._descripcion}
                setHasUpdates={setHasUpdates}
              />
            </div>
            <div className={`tab-pane fade show ${selectedTab[1] && 'active'}`} id="downloads">
              <Downloads
                mementoId={data?._idMemento}
                setHasDownloads={setHasDownloads}
                shopUrl={setShopLink(data?._listMaterias.Materia._idMateria)}
              />
            </div>
          </div>
        </div>
      </div>
      {!hasUpdates && !hasDownloads && (
        <div style={{height: '25vh'}}>
          <Spinner type={'-relative'} />
        </div>
      )}
    </div>
  );
};

export default Details;
