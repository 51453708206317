import {useParams} from 'react-router-dom';
import {useContext, useEffect} from 'react';
import {SearchContext} from 'App';
import Page from './Page';

export default function Search() {
  const {search = ''} = useParams();
  const {setSearch} = useContext(SearchContext);

  useEffect(() => {
    setSearch(search);
    // eslint-disable-next-line
  }, []);

  return <Page />;
}
