import propTypes from 'prop-types';
import {useState, createContext, useEffect} from 'react';
import ButtonGroup from './ButtonGroup';
import EditForm from './EditForm';
import FilesList from './FilesList';
import EditFiles from './EditFiles';
import Spinner from 'Components/Common/Spinner';
import useSessionStorage from 'Hooks/useSessionStorage';

ElementCard.propTypes = {
  element: propTypes.object
};

export const ElementContext = createContext();
export const EditContext = createContext();
export const FileListContext = createContext();
export const EditFilesContext = createContext();

export default function ElementCard({element}) {
  const {getItem, removeItem} = useSessionStorage('mementoOpened');
  const [edit, setEdit] = useState(getItem(element?._idMemento));
  const [showFiles, setShowFiles] = useState(false);
  const [editFiles, setEditFiles] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const statusPubli = () => {
    if (element?._indPublicado == 1)
      return (
        <button className="btn btn-outline-success ml-auto" disabled>
          Publicado
        </button>
      );
    else
      return (
        <button className="btn btn-outline-danger ml-auto" disabled>
          No Publicado
        </button>
      );
  };

  useEffect(() => {
    if (!edit) removeItem(element?._idMemento);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);

  return (
    <ElementContext.Provider value={element}>
      <EditContext.Provider value={{edit, setEdit}}>
        <FileListContext.Provider value={{showFiles, setShowFiles}}>
          <EditFilesContext.Provider value={{editFiles, setEditFiles}}>
            <div className="card border-light my-2">
              <div
                className="card-header border-0 d-flex align-items-center p-1"
                style={{backgroundColor: '#cdc'}}
              >
                <p className="pl-3 m-0">{element?._descripcion}</p>
                {statusPubli()}
              </div>
              <div className="card-body p-2" style={{backgroundColor: '#f0f4f0'}}>
                <div className="media">
                  <img
                    className="align-self-start mr-4"
                    width="100px"
                    src={element?.image}
                    alt={element?._descripcion}
                  />
                  <table className="table table-borderless media-body">
                    <tbody className="border-0">
                      <tr>
                        <td className="p-0">
                          <table className="table table-borderless table-responsive">
                            <tbody className="border-0">
                              <tr>
                                <td className="w-50">Código Producto</td>
                                <td>
                                  <span className="badge badge-pill badge-secondary">
                                    {element?._codigoMemento}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td width="200px">Código Navision</td>
                                <td>
                                  <span className="badge badge-pill badge-secondary">
                                    {element?._idProductoNav}
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          {isLoading && <Spinner type={'-relative'} margin={'ml-auto'} />}
                        </td>
                        <td>
                          <div className="d-flex justify-content-end">
                            <ButtonGroup setIsLoading={setIsLoading} />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {edit && <EditForm />}
              {showFiles && <FilesList />}
              {editFiles && <EditFiles />}
            </div>
          </EditFilesContext.Provider>
        </FileListContext.Provider>
      </EditContext.Provider>
    </ElementContext.Provider>
  );
}
