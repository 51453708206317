import FooterMenu from './Components/FooterMenu';
import FooterSocial from './Components/FooterSocial';

const Footer = () => {
  return (
    <>
      <FooterMenu />
      <FooterSocial />
    </>
  );
};

export default Footer;
