import {useQuery} from 'react-query';
import propTypes from 'prop-types';
import {useContext, useState, useEffect} from 'react';
import {AreaContext, AllAreasContext, SearchContext, CurrentPageContext} from 'App';

HeaderAreaSelect.propTypes = {
  isMobile: propTypes.bool,
  toggleGenerate: propTypes.func
};

export default function HeaderAreaSelect({isMobile, toggleGenerate}) {
  const [selected, setSelected] = useState('Área');
  const {area, setArea} = useContext(AreaContext);
  const {allAreas, setAllAreas} = useContext(AllAreasContext);
  const {setSearch} = useContext(SearchContext);
  const {setCurrentPage} = useContext(CurrentPageContext);
  const {isLoading, data, error} = useQuery('area', () =>
    fetch('/api/Area/Get')
      .then((res) => res.json())
      .then((data) => {
        return [{_descMateria: 'Todas', _idMateria: -1, _slug: 'todas'}, ...data.data.Materia];
      })
  );

  useEffect(() => {
    if (!area?._slug || area?._slug === 'todas') setSelected('Área');
    else setSelected(area?._descMateria);
    // eslint-disable-next-line
  }, [area, allAreas]);

  useEffect(() => {
    if (!isLoading) setAllAreas(data);
    // eslint-disable-next-line
  }, [isLoading]);

  if (isLoading || error) {
    if (isMobile === true)
      return (
        <select id="area" className="form-control">
          {isLoading && <option>Cargando...</option>}
          {error && <option>Error al cargar</option>}
        </select>
      );
    else
      return (
        <div className="dropdown-menu area-items" aria-labelledby="dropdownArea">
          {isLoading && <a className="dropdown-item">Cargando...</a>}
          {error && <a className="dropdown-item">Error al cargar</a>}
        </div>
      );
  }

  const cleanStates = () => {
    setSearch('');
    setCurrentPage(1);
    toggleGenerate();
  };

  const handleClick = (item) => {
    setArea(item);
    setSelected(item?._descMateria ?? 'Todas');
    cleanStates();
  };

  const handleClickMobile = (item) => {
    let areaSlug = data?.find((element) => element._descMateria == item);
    setArea(areaSlug);
    cleanStates();
  };

  if (isMobile === true)
    return (
      <select id="area" className="form-control" onChange={(e) => handleClickMobile(e.target.value)}>
        {data?.map((item) => {
          return (
            <option value={item._descMateria} key={item._idMateria}>
              {item._descMateria}
            </option>
          );
        })}
      </select>
    );

  return (
    <>
      <button
        className="btn btn-link dropdown-toggle pb-0"
        type="button"
        id="dropdownArea"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {selected}
      </button>
      <div className="dropdown-menu area-items" aria-labelledby="dropdownArea">
        {data?.map((item) => {
          return (
            <a
              className="dropdown-item"
              key={item._idMateria}
              onClick={() => handleClick(item)}
              style={{cursor: 'pointer'}}
            >
              {item._descMateria}
            </a>
          );
        })}
      </div>
    </>
  );
}
