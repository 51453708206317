import {useHistory} from 'react-router-dom';
import propTypes from 'prop-types';
import Pagination from 'Components/Common/Pagination';
// import banner from 'img/banner.png';
import {PAGE_LIMIT} from 'define';
import { useEffect } from 'react';

Show.propTypes = {
  data: propTypes.array.isRequired,
  message: propTypes.element.isRequired,
  totalPages: propTypes.number.isRequired,
  currentPage: propTypes.number.isRequired,
  numElements: propTypes.number.isRequired
};

export default function Show({data, message, totalPages, currentPage, numElements}) {
  const history = useHistory();
  useEffect(() => {
    //Hay que inicializarlo una vez que se haya resuelto la peticion porque hasta entonces no existe el div
    if (data) {
      window.googletag.cmd.push(function() { 
        window.googletag.display('div-gpt-ad-1689751321019-0');
        window.googletag.pubads().refresh(); 
      }); 
    }
  }, [data]);

  const handleClick = (e, slug) => {
    e.preventDefault();
    history.push(`/memento/${slug}`);
    window.scrollTo(0, 0);
  };

  /**
   * Devuelve un array con HTML de los elementos de la lista
   */
  const loopElements = () => {
    let arr = [];

    for (let i = 0; i < numElements; i++) {
      arr.push(
        <div className="px-3 px-md-0 col-12 col-md-6 col-lg-3 col-xl-2 memento-box" key={i}>
          <a
            className="h-100"
            href={`/memento/${data[i]?._slug}`}
            onClick={(e) => handleClick(e, data[i]?._slug)}
          >
            <img src={data[i]?.image} className="img-responsive" />
            <p>{data[i]?._descripcion}</p>
          </a>
        </div>
      );
      // Añade un Banner entre medias
      if (
        (i % 9 === 0 && i > 0 && i < PAGE_LIMIT / 2) ||
        (numElements < PAGE_LIMIT / 2 && i === numElements - 1)
      )
        arr.push(
          
          <div className="col-12 my-3 text-center d-none d-md-block" key={-1}>
            <div id='div-gpt-ad-1689751321019-0' style={{minWidth: 920+'px', minHeight: 150+'px'}}></div>
          </div>
          
          // <div className="col-12 my-3 text-center d-none d-md-block" key={-1}>
          //   <a href="https://lefebvre.es/tienda/" target="_blank">
          //     <img src={banner} className="img-fluid" />
          //   </a>
          // </div>
        );
    }

    return arr;
  };

  /**
   * Imprime el Listado de Mementos y la paginación
   */
  return (
    <div className="container mt-4">
      <div className="row">{message}</div>
      <div className="row mt-2 justify-content-center">{loopElements()}</div>
      <Pagination totalPages={totalPages} currentPage={currentPage} generate={true} />
    </div>
  );
}
