import Form from './Components/Form';
import {useContext} from 'react';
import {ElementContext, EditContext} from './ElementCard';

export default function EditForm() {
  const element = useContext(ElementContext);
  const {edit, setEdit} = useContext(EditContext);

  const handleEdit = () => {
    setEdit(!edit);
  };

  return (
    <div className="card-body p-2" style={{backgroundColor: '#e6eae6'}}>
      <Form handleClose={handleEdit} element={element} />
    </div>
  );
}
