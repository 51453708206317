import {Switch, Route, useParams, useRouteMatch} from 'react-router-dom';
import {useContext, useEffect} from 'react';
import {AreaContext, AllAreasContext} from 'App';
import Search from './Search';
import Page from './Page';

export default function Area() {
  const {area = ''} = useParams();
  const {path} = useRouteMatch();
  const {allAreas} = useContext(AllAreasContext);
  const {setArea} = useContext(AreaContext);

  useEffect(() => {
    let areaValue = allAreas ? allAreas?.find((item) => item._slug === area) : '';
    setArea(areaValue);
    // eslint-disable-next-line
  }, [allAreas]);

  return (
    <>
      <Switch>
        <Route path={`${path}/buscar/:search/:page(\\d+)?`}>
          <Search />
        </Route>
        <Route path={`${path}/:page(\\d+)?`}>
          <Page />
        </Route>
      </Switch>
    </>
  );
}
