function useSessionStorage(storage) {
  const getItems = () => {
    return JSON.parse(sessionStorage.getItem(storage));
  };

  const setItems = (updated) => {
    sessionStorage.setItem(storage, JSON.stringify(updated));
  };

  const removeItem = (item) => {
    let items = getItems();
    delete items[item];
    setItems(items);
  };

  const getItem = (item) => {
    let items = getItems();
    if (items && items[item]) return items[item];
    return false;
  };

  const toggleItem = (item) => {
    let items = getItems();
    if (items[item]) removeItem(item);
    else {
      items[item] = true;
      setItems(items);
    }
  };

  const countItems = () => {
    let items = getItems();
    if (!items) return 0;
    return Object.keys(items).length;
  };

  return {getItem, removeItem, toggleItem, countItems, getItems};
}

export default useSessionStorage;
