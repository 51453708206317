import {FILTER} from 'define';

export function fetchMementos(arr = []) {
  let storedAuthToken = localStorage.getItem('token');
  let filterSlug = arr['filterSlug'] ?? 0;
  let area = arr['area'] ?? [];
  let filter = FILTER.find((item) => item.slug === filterSlug)?.id;
  let route = arr['url'] ?? '/api/Memento/List';
  let params = {
    numPage: arr['currentPage'] ?? 1,
    idDate: filter ?? 0,
    idArea: area?._idMateria ?? -1,
    search: arr['search'] ?? '',
    indPublish: arr['indPublish'] ?? 1,
    indRemoved: arr['indRemoved'] ?? 2
  };

  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(params)
  };

  if (storedAuthToken) requestOptions.headers.Authorization = `Bearer ${storedAuthToken}`;

  return () =>
    fetch(route, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data.code == 401) {
          localStorage.removeItem('token');
          fetchMementos();
        } else return data;
      });
}
