import propTypes from 'prop-types';

Spinner.propTypes = {
  type: propTypes.string,
  margin: propTypes.string
};

export default function Spinner({type, margin}) {
  let className = 'preloader-holder-blue';

  if (type) className += type;
  if (margin) className += ` ${margin}`;

  return <div className={className}></div>;
}
