import {useContext, useEffect, useState} from 'react';
import {FilesContext} from './Downloads';
import moment from 'moment';

const orders = ['Fecha', 'Título', 'Descripción', 'Nombre de fichero'];
const types = [
  <span className="lf-icon-arrow-down" key="0"></span>,
  <span className="lf-icon-arrow-up" key="1"></span>
];

function compareDate(a, b) {
  let aParsed = moment(a._fecha, 'DD/MM/YYYY hh:mm:ss').format();
  let bParsed = moment(b._fecha, 'DD/MM/YYYY hh:mm:ss').format();
  return new Date(bParsed) - new Date(aParsed);
}

function compareTitle(a, b) {
  return a._titulo.localeCompare(b._titulo);
}

function compareDesc(a, b) {
  return a._descripcion.localeCompare(b._descripcion);
}

function compareName(a, b) {
  return a._nombre.localeCompare(b._nombre);
}

export default function FileOrder() {
  const [orderField, setOrderField] = useState('Fecha');
  const [orderType, setOrderType] = useState(0);
  const {files, setFiles} = useContext(FilesContext);

  const handleFilter = () => {
    if (files) {
      switch (orderField) {
        case orders[0]: // por Fecha
          setFiles(
            files.slice().sort((a, b) => (orderType === 0 ? compareDate(a, b) : compareDate(b, a)))
          );
          break;
        case orders[1]: // por Titulo
          setFiles(
            files
              .slice()
              .sort((a, b) => (orderType === 0 ? compareTitle(a, b) : compareTitle(b, a)))
          );
          break;
        case orders[2]: // por Descripcion
          setFiles(
            files.slice().sort((a, b) => (orderType === 0 ? compareDesc(a, b) : compareDesc(b, a)))
          );
          break;
        case orders[3]: // por Nombre de fichero
          setFiles(
            files.slice().sort((a, b) => (orderType === 0 ? compareName(a, b) : compareName(b, a)))
          );
          break;
      }
    }
  };

  useEffect(() => {
    handleFilter();
    // eslint-disable-next-line
  }, [orderType, orderField]);

  const handleChange = (e, field) => {
    e.preventDefault();
    if (orderField != field) {
      setOrderField(field);
      setOrderType(0);
    } else setOrderType(1 - orderType);
  };

  return (
    <div className="dropdown">
      <button
        className="btn btn-secondary dropdown-toggle"
        type="button"
        id="dropdownShort"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {orderField} ({types[orderType]})
      </button>
      <div className="dropdown-menu dd-short" aria-labelledby="dropdownShort">
        {orders.map((item, key) => {
          return (
            <a className="dropdown-item" href="#" key={key} onClick={(e) => handleChange(e, item)}>
              {item} {orderField === item && <>({types[1 - orderType]})</>}
            </a>
          );
        })}
      </div>
    </div>
  );
}
