import {useParams} from 'react-router-dom';
import {useContext, useEffect} from 'react';
import {CurrentPageContext} from 'App';
import List from '../List';

export default function Page() {
  const {page = 1} = useParams();
  const {setCurrentPage} = useContext(CurrentPageContext);

  useEffect(() => {
    setCurrentPage(Number(page));
    // eslint-disable-next-line
  }, [page]);

  return <List />;
}
