import {useHistory, useRouteMatch} from 'react-router-dom';
import propTypes from 'prop-types';
import {useState, useContext, useEffect} from 'react';
import HeaderAreaSelect from './Components/HeaderAreaSelect';
import HeaderDateSelect from './Components/HeaderDateSelect';
import {
  FilterContext,
  AreaContext,
  SearchContext,
  CurrentPageContext,
  AuthContext,
  AdmSlugContext,
  queryClient
} from 'App';
import {TITLE, FILTER} from 'define';

HeaderDesktop.propTypes = {
  toggleGenerate: propTypes.func
};

export default function HeaderDesktop({toggleGenerate}) {
  const url = useRouteMatch('/adm');
  const history = useHistory();
  const {filter, setFilter} = useContext(FilterContext);
  const {setAdmSlug} = useContext(AdmSlugContext);
  const {setArea} = useContext(AreaContext);
  const {setSearch} = useContext(SearchContext);
  const {setCurrentPage} = useContext(CurrentPageContext);
  const {isLogged} = useContext(AuthContext);
  const [input, setInput] = useState('');
  const [selectedFilter, setSelectedFilter] = useState();

  const handleValue = (e) => {
    setInput(e.target.value);
    if (!url) toggleGenerate();
  };

  const generateUrl = (e) => {
    e?.preventDefault();
    setInput('');
    if (!url) {
      setCurrentPage(1);
      setSearch(input);
      toggleGenerate();
    } else {
      setAdmSlug(input);
      queryClient.refetchQueries();
    }
  };

  const goHome = (e) => {
    e.preventDefault();
    setInput('');
    setFilter('todos');
    setArea('');
    setSearch('');
    setCurrentPage(1);
    history.push('/');
  };

  useEffect(() => {
    let selected = FILTER.find((item) => item.slug === filter)?.value;
    setSelectedFilter(selected ?? 'Todos');
    // eslint-disable-next-line
  }, [filter]);

  const handleClick = () => {
    if (url) history.push('/');
    else history.push('/adm');
  };

  return (
    <header className="sticky-top d-none d-md-block">
      {isLogged && (
        <nav className="navbar navbar-dark bg-dark p-1">
          <div className="container">
            <button className="btn btn-outline-light btn-sm py-0" onClick={() => handleClick()}>
              {url ? 'Ver como usuario' : 'Administrar'}
            </button>
            <button
              className="btn btn-outline-danger btn-sm py-0"
              onClick={() => history.push('/adm/logout')}
            >
              Salir
            </button>
          </div>
        </nav>
      )}
      <div className="container">
        <div className="row align-items-center py-3">
          <a
            className="col-4"
            onClick={(e) => goHome(e)}
            style={{cursor: 'pointer', textDecoration: 'none'}}
            href="/"
          >
            <h1 className="logo">{TITLE}</h1>
          </a>
          <div className="col-4 text-center">
            <a href="https://lefebvre.es/" target="_blank" title="Ir a página de Lefebvre">
              <img
                src="https://assets.lefebvre.es/media/logos/web/comunes/lefebvre-147x30.png"
                alt="Lefebvre"
              />
            </a>
          </div>
          <div className="col text-right external-links">
            <ul>
              <li>
                <a href="https://lefebvre.es/tienda/" title="Tienda" target="_blank">
                  <span className="lf-icon-shop"></span>
                </a>
              </li>
              <li>
                <a
                  href="https://online.elderecho.com/login.do?pestana=pestana_actualidad"
                  title="Actum"
                  target="_blank"
                >
                  <span className="lf-icon-actum"></span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container-fluid search-container">
        <div className="row">
          <div className="container">
            <div className="row">
              <div className="col-3">
                {!url && (
                  <div className="dropdown area-dd">
                    <HeaderAreaSelect toggleGenerate={toggleGenerate} />
                  </div>
                )}
              </div>
              <form className="col text-center" onSubmit={(e) => generateUrl(e)}>
                <div className="col-md-12 col-lg-10 input-group px-0 h-100">
                  <input
                    id="search-text"
                    type="search"
                    className="h-100 form-control search-input"
                    placeholder={
                      !url
                        ? 'Escribe el título, área del derecho o ley concreta para encontrar la publicación'
                        : 'Escribe el título para encontrar el memento o la descarga'
                    }
                    aria-label="Cadena de búsqueda"
                    aria-describedby="sbutton"
                    value={input}
                    onChange={(e) => handleValue(e)}
                    required
                    minLength="2"
                  />
                  {!url && (
                    <div className="input-group-append">
                      <button
                        className="btn btn-outline-secondary btn-fechas dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {selectedFilter}
                      </button>
                      <HeaderDateSelect toggleGenerate={toggleGenerate} />
                    </div>
                  )}
                  <div className="input-group-append">
                    <button className="btn btn-primary" type="submit" id="sbutton">
                      <span className="lf-icon-search"></span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
