import propTypes from 'prop-types';
import {useContext} from 'react';
import {CurrentPageContext, GenerateUrlContext} from 'App';

Pagination.propTypes = {
  totalPages: propTypes.number.isRequired,
  currentPage: propTypes.number.isRequired,
  generate: propTypes.bool.isRequired,
  setPage: propTypes.func,
  scrollTo: propTypes.object
};

export default function Pagination({totalPages, currentPage, generate, setPage, scrollTo}) {
  const {setCurrentPage} = useContext(CurrentPageContext);
  const {setGenerate} = useContext(GenerateUrlContext);

  const handleClick = (i) => {
    if (setPage) setPage(i);
    else setCurrentPage(i);
    if (scrollTo) scrollTo.current.scrollIntoView();
    else window.scrollTo(0, 0);
    if (generate) setGenerate(true);
  };

  const isActive = (page) => {
    if (currentPage === page) return <span className="sr-only">(Activo)</span>;
  };

  const loopElements = () => {
    let arr = [];
    let ActiveClass;

    if (totalPages === 1) return arr;

    for (let i = 0; i < totalPages; i++) {
      if (currentPage === i + 1) ActiveClass = 'active';
      else ActiveClass = '';
      arr.push(
        <li className={`page-item ${ActiveClass}`} key={i}>
          <a className="page-link" onClick={() => handleClick(i + 1)}>
            {i + 1} {isActive()}
          </a>
        </li>
      );
    }

    return arr;
  };

  const prevButton = () => {
    if (currentPage > 1)
      return (
        <li className="page-item">
          <a
            className="page-link d-flex align-items-center previous"
            onClick={() => handleClick(currentPage - 1)}
          >
            <span className="lf-icon-angle-left"></span>
          </a>
        </li>
      );
  };

  const nextButton = () => {
    if (currentPage < totalPages)
      return (
        <li className="page-item">
          <a
            className="page-link d-flex align-items-center next"
            onClick={() => handleClick(currentPage + 1)}
          >
            <span className="lf-icon-angle-right"></span>
          </a>
        </li>
      );
  };

  return (
    <div className="row mt-3">
      <div className="col text-right">
        <nav aria-label="Pagination">
          <ul className="pagination justify-content-end align-items-center">
            {prevButton()}
            {loopElements()}
            {nextButton()}
          </ul>
        </nav>
      </div>
    </div>
  );
}
