import {useState} from 'react';
import Spinner from 'Components/Common/Spinner';

function fetchCreate(login, pass, setResponseGood, setCall) {
  let storedAuthToken = localStorage.getItem('token');
  let params = {
    username: login,
    password: pass
  };
  setCall(true);

  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json', Authorization: `Bearer ${storedAuthToken}`},
    body: JSON.stringify(params)
  };

  return fetch('/api/adm/createUser', requestOptions)
    .then((res) => res.json())
    .then((res) => {
      setResponseGood(res);
      setCall(false);
    });
}

function CreateUser() {
  const [login, setLogin] = useState('');
  const [pass, setPass] = useState('');
  const [responseBad, setResponseBad] = useState('');
  const [responseGood, setResponseGood] = useState('');
  const [passRepeat, setPassRepeat] = useState('');
  const [call, setCall] = useState(false);

  const handleSubmit = (e) => {
    if (responseBad) setResponseBad('');
    e.preventDefault();
    if (pass !== passRepeat) {
      setResponseBad('Las contraseñas no coinciden');
      setPass('');
      setPassRepeat('');
    } else {
      fetchCreate(login, pass, setResponseGood, setCall);
    }
  };

  const handleInputLogin = (e) => {
    if (responseBad) setResponseBad('');
    setLogin(e.target.value);
  };

  const handleInputPass = (e) => {
    if (responseBad) setResponseBad('');
    setPass(e.target.value);
  };

  const handleInputPassRepeat = (e) => {
    if (responseBad) setResponseBad('');
    setPassRepeat(e.target.value);
  };

  if (call)
    return (
      <div style={{height: '25vh'}}>
        <Spinner type={'-relative'} />
      </div>
    );

  if (!call)
    return (
      <form className="container p-5 w-25" onSubmit={(e) => handleSubmit(e)}>
        <h2 className="text-center mb-5">Crear un usuario</h2>
        {responseBad && (
          <div className="alert alert-danger" role="alert">
            {responseBad}
          </div>
        )}
        {responseGood && (
          <div className="alert alert-success" role="alert">
            {responseGood}
          </div>
        )}
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            placeholder="Nombre de usuario"
            value={login}
            required
            minLength={3}
            onChange={(e) => handleInputLogin(e)}
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            className="form-control"
            placeholder="Contraseña"
            value={pass}
            required
            minLength={5}
            onChange={(e) => handleInputPass(e)}
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            className="form-control"
            placeholder="Repite la contraseña"
            value={passRepeat}
            required
            minLength={5}
            onChange={(e) => handleInputPassRepeat(e)}
          />
        </div>
        <div className="form-group">
          <button type="submit" className="btn btn-primary btn-block p-3">
            Entrar
          </button>
        </div>
      </form>
    );
}

export default CreateUser;
