import propTypes from 'prop-types';
import Form from './Components/Form';

CreateForm.propTypes = {
  handleClick: propTypes.func.isRequired
};

export default function CreateForm({handleClick}) {
  return (
    <div className="card container my-2" style={{backgroundColor: '#e6eae6'}}>
      <div className="card-body p-2">
        <Form handleClose={handleClick} />
      </div>
    </div>
  );
}
