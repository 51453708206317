import propTypes from 'prop-types';
import {useQuery} from 'react-query';
import {useState, useEffect, createContext} from 'react';
// import banner from 'img/banner.png';
import Spinner from 'Components/Common/Spinner';
import FileOrder from './FileOrder';
// import Viewer from 'Components/Common/Viewer/Viewer';

export const FilesContext = createContext();

Downloads.propTypes = {
  mementoId: propTypes.number,
  setHasDownloads: propTypes.func.isRequired,
  shopUrl: propTypes.string
};

export default function Downloads({mementoId, setHasDownloads}) {
  const [files, setFiles] = useState([]);
  // const [selected, setSelected] = useState({});
  // const [fileType, setFileType] = useState('');
  const {data, isLoading} = useQuery(`memento/files/${mementoId}`, () =>
    fetch(`/api/Memento/Files/${mementoId}`).then((res) => res.json())
  );

  useEffect(() => {
    if (!isLoading) {
      let response = data.data.Fichero;
      setFiles(response);
      if (response.length) setHasDownloads(true);
    }
    // eslint-disable-next-line
  }, [data, isLoading]);

  // const handleClick = (e, item) => {
  //   e.preventDefault();
  //   setSelected(item);
  //   setFileType(item.type);
  // };

  // const handleTarget = (type) => {
  //   if (type === 'pdf') return '#modalContent';
  //   else if (type === 'html') return '#modalContent';
  //   else return '';
  // };

  // const handleHref = (item) => {
  //   if (item.type === 'image' || item.type === 'doc')
  //     return (
  //       <a href={item.url} target="_blank" className="file-type">
  //         <span className={item.iconCss}></span>
  //       </a>
  //     );
  //   return (
  //     <a
  //       href="#"
  //       className="file-type"
  //       data-toggle="modal"
  //       data-target={handleTarget(item.type)}
  //       onClick={(e) => handleClick(e, item)}
  //     >
  //       <span className={item.iconCss}></span>
  //     </a>
  //   );
  // };

  const handleHrefMobile = (item) => {
    console.log(item);
    return (
      <a href={item.url} target="_blank" className="file-type">
        <span className={item.iconCss}></span>
      </a>
    );
  };

  /**
   * Spinner de carga
   */
  if (isLoading)
    return (
      <div style={{height: '25vh'}}>
        <Spinner type={'-relative'} />
      </div>
    );

  if (files?.length)
    return (
      <>
        <p className="tab-info mt-4">Estas son las descargas para tu memento.</p>
        {/* <a href={shopUrl} target="_blank">
          <img src={banner} className="d-block img-responsive my-3" />
        </a> */}

        <div className="data-table col-12">
          <div className="row head-content py-3">
            <div className="col-6 col-md-3 order-md-1">Formato</div>
            <div className="col-6 col-md-4 order-md-3 text-right">
              <FilesContext.Provider value={{files, setFiles}}>
                <FileOrder />
              </FilesContext.Provider>
            </div>
            <div className="col-12 col-md-5 order-md-2">Descripción</div>
          </div>
          <div className="row scroll-content">
            {files?.map((item, key) => {
              return (
                <div className="content-row" key={key}>
                  <div className="col-12 col-md-3 col-lg-2 pb-0 pb-md-3 d-none d-md-none d-lg-block">{handleHrefMobile(item)}</div>
                  <div className="col-12 col-md-3 col-lg-2 pb-0 pb-md-3 d-md-block d-lg-none">
                    {handleHrefMobile(item)}
                  </div>
                  <div className="col-12 col-md-9 col-lg-10 pt-0 pt-md-3">
                    {item._titulo}
                    <br />
                    {item._descripcion}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* <Viewer type={fileType} selected={selected} /> */}
      </>
    );

  return <></>;
}
