import {Switch, Route, useParams, useRouteMatch} from 'react-router-dom';
import {useContext, useEffect} from 'react';
import {FilterContext} from 'App';
import Area from './Area';
import Search from './Search';
import Page from './Page';

export default function Filter() {
  const {filter = 'todos'} = useParams();
  const {path} = useRouteMatch();
  const {setFilter} = useContext(FilterContext);

  useEffect(() => {
    setFilter(filter);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Switch>
        <Route path={`${path}/area/:area`}>
          <Area />
        </Route>
        <Route path={`${path}/buscar/:search/:page(\\d+)?`}>
          <Search />
        </Route>
        <Route path={`${path}/:page(\\d+)?`}>
          <Page />
        </Route>
      </Switch>
    </>
  );
}
