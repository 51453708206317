import {useHistory} from 'react-router-dom';
import propTypes from 'prop-types';
import {useState, useContext} from 'react';
import HeaderAreaSelect from './Components/HeaderAreaSelect';
import HeaderDateSelect from './Components/HeaderDateSelect';
import {FilterContext, AreaContext, SearchContext, CurrentPageContext} from 'App';
import {TITLE} from 'define';

HeaderMobile.propTypes = {
  toggleGenerate: propTypes.func
};

export default function HeaderMobile({toggleGenerate}) {
  const history = useHistory();
  const {setFilter} = useContext(FilterContext);
  const {setArea} = useContext(AreaContext);
  const {setSearch} = useContext(SearchContext);
  const {setCurrentPage} = useContext(CurrentPageContext);
  const [input, setInput] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
    document.body.classList.toggle('no-scroll', !isVisible);
  };

  const handleValue = (e) => {
    setInput(e.target.value);
    toggleGenerate();
  };

  const generateUrl = (e) => {
    window.scrollTo(0, 0);
    toggleVisibility();
    e?.preventDefault();
    setCurrentPage(1);
    setSearch(input);
    toggleGenerate();
  };

  const goHome = () => {
    window.scrollTo(0, 0);
    setFilter('todos');
    setArea('');
    setSearch('');
    setCurrentPage(1);
    history.push('/');
  };

  return (
    <>
      <div className="container-fluid navbar-lf-logo d-md-none">
        <div className="row text-center">
          <a
            className="col central-nav-col-xs"
            href="https://lefebvre.es/"
            target="_blank"
            title="Enlace externo. Abre en ventana nueva"
          >
            <img
              src="https://assets.lefebvre.es/media/logos/web/comunes/lefebvre-147x30.png"
              alt="LEFEBVRE"
            />
          </a>
        </div>
      </div>

      <nav className="navbar navbar-expand-md navbar-lf-home sticky-top d-md-none">
        <div className="container">
          <div className="row w-100 no-gutters">
            <a className="col central-nav-col" onClick={() => goHome()}>
              <h1 className="logo">{TITLE}</h1>
            </a>
            <button
              className="col-1 navbar-toggler px-0"
              type="button"
              id="show-search-box"
              onClick={toggleVisibility}
            >
              <span className="navbar-toggler-icon lf-icon-search"></span>
            </button>
          </div>
        </div>
      </nav>

      <div className={`search-box-mobile ${isVisible && 'visible'}`}>
        <div className="row">
          <div className="col text-right">
            <a className="close-link" id="closeSearchBox" onClick={toggleVisibility}>
              <span className="lf-icon-close"></span>
            </a>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col px-0 mb-5">
            <form onSubmit={(e) => generateUrl(e)}>
              {/* SearchInput */}
              <div className="form-group">
                <label htmlFor="title">Título, área del derecho o ley concreta</label>
                <input
                  type="search"
                  className="form-control"
                  id="title"
                  value={input}
                  onChange={(e) => handleValue(e)}
                  minLength="2"
                />
              </div>
              {/* AreaSelector */}
              <div className="form-group">
                <label htmlFor="area">Área específica del derecho</label>
                <HeaderAreaSelect isMobile={true} toggleGenerate={toggleGenerate} />
              </div>
              {/* DateSelector */}
              <div className="form-group">
                <label htmlFor="rango">Rango de fechas</label>
                <HeaderDateSelect isMobile={true} toggleGenerate={toggleGenerate} />
              </div>
              <div className="form-group">
                <button className="btn btn-primary btn-block" id="sendForm" type="submit">
                  Buscar Mementos
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
