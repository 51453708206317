import {useContext, useState, useEffect, useRef} from 'react';
import {useQueries} from 'react-query';
import {EditFilesContext, ElementContext} from './ElementCard';
import Pagination from 'Components/Common/Pagination';
import Spinner from 'Components/Common/Spinner';
import useSessionStorage from 'Hooks/useSessionStorage';
import {queryClient} from 'App';

let storedAuthToken = localStorage.getItem('token');

function listFiles(currentPage, search) {
  let params = {
    numPage: currentPage,
    searchTerm: search
  };

  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${storedAuthToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  };

  return () => fetch('/api/adm/File/List', requestOptions).then((res) => res.json());
}

function saveChanges(id, getItems, filesPrev, setResponse, setUpdating) {
  let params = {
    idMemento: id,
    idFiles: Object.keys(getItems()),
    idFilesPrev: filesPrev
  };

  setUpdating(true);
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${storedAuthToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  };

  fetch('/api/adm/Memento/LinkFiles', requestOptions)
    .then((res) => res.json())
    .then((data) => {
      queryClient.refetchQueries();
      setResponse({code: data?.code, message: data?.message});
      setUpdating(false);
    });
}

export default function EditFiles() {
  const myRef = useRef(null);
  const element = useContext(ElementContext);
  const {getItem, toggleItem, getItems} = useSessionStorage(
    `mementoLinkedFiles${element?._idMemento}`
  );
  const {setEditFiles} = useContext(EditFilesContext);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [inputVal, setInputVal] = useState('');
  const [search, setSearch] = useState('');
  const [prevFiles, setPrevFiles] = useState([]);
  const [updating, setUpdating] = useState(false);
  const [response, setResponse] = useState({code: 0, message: ''});
  const results = useQueries([
    {
      queryKey: [`file-list-${search}`, currentPage],
      queryFn: listFiles(currentPage, search)
    },
    {
      queryKey: [`file-list-${search}`, currentPage + 1],
      queryFn: listFiles(currentPage + 1, search)
    },
    {
      queryKey: `memento-files-${element?._idMemento}`,
      queryFn: () =>
        fetch(`/api/Memento/Files/${element?._idMemento}`)
          .then((res) => res.json())
          .then((data) => data.data.Fichero)
    }
  ]);
  const data = results[0];
  const fetchFiles = results[2];

  useEffect(() => {
    if (!data.isLoading) {
      let total = data.data?.result?._IntTotalRecs;
      let totalPages = Math.ceil(total / 10);
      setTotalPages(total && totalPages);
    }
    // eslint-disable-next-line
  }, [data.data, data.isLoading]);

  useEffect(() => {
    if (!fetchFiles.isLoading) {
      sessionStorage.setItem(`mementoLinkedFiles${element?._idMemento}`, '{}');
      let fetched = fetchFiles.data?.map((item) => item._idFichero);
      fetched = Array.from(new Set(fetched));
      fetched.forEach((item) => {
        if (!getItem[item]) toggleItem(item);
      });
      setPrevFiles(fetched);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchFiles.data, fetchFiles.isLoading]);

  const handleClose = () => {
    setEditFiles(false);
  };

  const handleChange = (id) => {
    toggleItem(id);
  };

  const handleClick = () => {
    saveChanges(element?._idMemento, getItems, prevFiles, setResponse, setUpdating);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSearch(inputVal);
  };

  const handleInputClick = (e) => {
    if (!e.target.value) setSearch('');
  };

  return (
    <div className="card-body p-2" style={{backgroundColor: '#e6eae6'}}>
      <button
        className="btn-close float-right"
        aria-label="Cerrar"
        onClick={() => handleClose(false)}
      ></button>
      <div className="container m-3" ref={myRef}>
        <h5 className="p-2">Asociar Descargas</h5>

        <form className="input-group input-group-sm mb-3" onSubmit={(e) => handleSubmit(e)}>
          <span className="input-group-text" id="inputGroup-sizing-sm">
            Buscar descargas
          </span>
          <input
            type="search"
            className="form-control"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-sm"
            required
            minLength="3"
            value={inputVal}
            onChange={(e) => setInputVal(e.target.value)}
            onInput={(e) => handleInputClick(e)}
          />
          <button className="btn btn-primary mr-3" id="button-addon2">
            <span className="lf-icon-search"></span>
          </button>
        </form>

        {response.code > 0 && response.code && (
          <div
            className={`alert alert-dismissible fade show mr-3 ${
              response.code == 200 ? 'alert-success' : 'alert-danger'
            }`}
            role="alert"
          >
            {response.message}
          </div>
        )}

        {(data.isLoading || data.isPreviousData || fetchFiles.isLoading || updating) && (
          <Spinner type={'-relative'} margin={'mt-5'} />
        )}
        {!data.isLoading && !data.isPreviousData && !fetchFiles.isLoading && !updating && (
          <>
            {data?.data.data?.Fichero?.map((item, key) => {
              let checked = getItem(item._idFichero);
              return (
                <div className="card mb-3 mr-3" key={key}>
                  <div className="card-header py-1">
                    <h5 className="mb-0">{item._titulo}</h5>
                    <p className="m-0" style={{color: '#666'}}>
                      {item._descripcion}
                    </p>
                  </div>
                  <div className="media ml-3">
                    <span className={`${item.iconCss} file-type m-2`}></span>
                    <label className="form-check-label m-3" htmlFor="flexSwitchCheckDefault">
                      {item.fileName}
                    </label>
                    <div className="form-check form-switch ml-auto my-auto">
                      <input
                        key={Math.random()}
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        defaultChecked={checked}
                        onChange={() => handleChange(item._idFichero)}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        )}
        <div className="d-grid mx-auto">
          <div className="d-grid mx-auto">
            <Pagination
              totalPages={totalPages ?? 1}
              currentPage={currentPage}
              generate={false}
              setPage={setCurrentPage}
              scrollTo={myRef}
            />
          </div>
          <button
            className="btn btn-success mr-3"
            disabled={data.isLoading || data.isPreviousData || fetchFiles.isLoading || updating}
            onClick={() => handleClick()}
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  );
}
